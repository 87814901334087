import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { Card, CardMedia, CardActions, CardHeader } from '@material-ui/core';
import { CommonZonesTitle } from './'
import { S3File } from '../s3';
import { commonZonesService } from '../../services/'

export default class CommonZonesEdit extends Component {
    state = {
        commonZone: {}
    }

    componentDidMount() {
        this.fetchImg()
    }

    fetchImg = async () => {
        await commonZonesService.find({ query: { id: this.props.id }, paginate: false })
            .then(it => this.setState({ commonZone: it.data[0] }))
    }

    handleUploadFinish = async (path) => {
        await commonZonesService.patch(this.props.id, { icon_path: path })
        this.fetchImg()
    }

    render() {
        const { commonZone } = this.state;
        return (
            <Edit title={<CommonZonesTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" label="Nombre" />
                    <Card className="box-shadow-0">
                        <CardHeader
                            title="Logo"
                        />
                        <CardMedia
                            className="card-media-icon"
                            component="img"
                            alt="Contemplative Reptile"
                            image={`https://construcaribe.s3.amazonaws.com/${commonZone.icon_path}`}
                            title="Contemplative Reptile"
                        />
                        <CardActions>
                            <S3File handleUploadFinish={this.handleUploadFinish} idComponent="main" path={"projects-images"} idComponent="logo-projets-sales-company" />
                        </CardActions>
                    </Card>
                </SimpleForm>
            </Edit>
        )
    }
};