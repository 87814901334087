import React, { Component } from 'react';
import {
    List, Datagrid, TextField, NumberField,
    EditButton, Filter, SelectInput, DeleteButton
} from 'react-admin';

const status = [
    { value: "Activa", description: "Activa" },
    { value: "Inactiva", description: "Inactiva" },
];

const ConstructionCompaniesFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="status" choices={status} optionText="description" optionValue="value" label="Estado" alwaysOn />

    </Filter>
);

export default class ConstructionCompaniesList extends Component {
    render() {
        return (
            <List {...this.props} title="Listado de Compañías" filters={<ConstructionCompaniesFilter />}>
                <Datagrid>
                    <TextField source="id" label="id" />
                    <TextField source="name" label="Nombre de Empresa" />
                    <NumberField source="phone" label="Teléfono" />
                    <TextField source="email" label="Email" />
                    <NumberField source="nit" label="NIT" />
                    <TextField source="status" label="status" />
                    <EditButton label="" />
                    <DeleteButton label="" />
                </Datagrid>
            </List>
        )
    }
};