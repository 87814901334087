import React, { Component } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton
} from 'react-admin'

export default class ConfigurationsList extends Component {

  render() {
    return (
      <List
        {...this.props}
        title="Variables del sistema"
      >
        <Datagrid>
          <TextField source="id" label="ID" />
          <TextField source="key" label="Clave" />
          <TextField source="value" label="Valor" />
          <TextField source="description" label="Descripción" />
          <EditButton label="" />
          <DeleteButton label="" />
        </Datagrid>
      </List>
    )
  }
}