import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton
} from 'react-admin';

export default class BlogsList extends Component {
    render() {
        return (
            <List {...this.props} title="Listado de blogs">
                <Datagrid>
                    <TextField source="id" label="id" />
                    <TextField source="title" label="Titulo" />
                    <EditButton label="" />
                </Datagrid>
            </List>
        )
    }
};