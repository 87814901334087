import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Typography, Box, Paper } from '@material-ui/core';
import { projectsService } from '../../services/feathers-rest';
import ProjectMain from './components/project-main';
import ProjectPhase from './components/project-phase';
import ProjectsSalesCompanies from './components/projects-sales-companies';
import ProjectZonesInterest from './components/project-zones-interest';
import ProjectImages from './components/project-images';
import CommonZones from './components/common-zones';

function TabPanel(props) {
  const { children, value = 0, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default class SimpleTabs extends Component {
  state = {
    projectStatus: {},
    loadProject: false
  }

  async componentDidMount() {
    this.fetchProject()
  }

  fetchProject = async () => {
    const project = await projectsService.find({ query: { id: this.props.match.params.id }, paginate: false })
      .then(it => it.data[0])
  }

  handleChangeTabs(event, newValue) {
    this.setState({ value: newValue });
    const { value, id } = this.state;
    if (newValue == 0) {
      this.setState({ loadProject: true })
      projectsService.find({ query: { id: id }, paginate: false }).then(result => this.setState({ project: result.data[0] }))
    }
  }

  render() {
    const { projectStatus } = this.state;
    return (
      <div >
        <AppBar position="static" style={{ backgroundColor: 'white', color: 'black' }}>
          <Tabs value={this.state.value} onChange={(e, v) => this.handleChangeTabs(e, v)} aria-label="simple tabs example">
            <Tab label="Datos principales" className="d-flex"  {...a11yProps(0)} />
            <Tab label="Fases" {...a11yProps(1)} />
            <Tab label="Compañía Vendedora" {...a11yProps(2)} />
            <Tab label="Zonas de interes" {...a11yProps(3)} />
            <Tab label="imagenes" {...a11yProps(4)} />
            <Tab label="Zonas Comunes" {...a11yProps(5)} />
          </Tabs>
        </AppBar>
        <Paper>
          <TabPanel value={this.state.value} index={0}>
            <ProjectMain id={this.props.match.params.id} loadProject={this.state.loadProject} loadProjectFalse={() => this.setState({ loadProject: false })} />
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            <ProjectPhase id={this.props.match.params.id} />
          </TabPanel>
          <TabPanel value={this.state.value} index={2}>
            <ProjectsSalesCompanies id={this.props.match.params.id} />
          </TabPanel>
          <TabPanel value={this.state.value} index={3}>
            <ProjectZonesInterest id={this.props.match.params.id} />
          </TabPanel>
          <TabPanel value={this.state.value} index={4}>
            <ProjectImages id={this.props.match.params.id} />
          </TabPanel>
          <TabPanel value={this.state.value} index={5}>
            <CommonZones id={this.props.match.params.id} />
          </TabPanel>
        </Paper>
      </div>
    )
  }


}