import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

export default (props) => {
  return (
    <List {...props} title="Listado de Revistas">
      <Datagrid>
        <TextField source="id" label="id" />
        <TextField source="name" label="Nombre" />
        <TextField source="link" label="Link" />
        <TextField source="position" label="Prioridad" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
