import React,{Component} from 'react';
import { Edit, SimpleForm, TextInput,  } from 'react-admin';
import {ZonesInterestTitle} from './'

export default class ZonesInteretsEdit extends Component{
    render(){
        return(
            <Edit title={<ZonesInterestTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" label="Nombre"/>
                </SimpleForm>
            </Edit>
        )
    }
};