import React, { Component } from 'react';
import {
  Button, TextField, Dialog, DialogActions,
  DialogContent, Grid, Select, MenuItem, DialogTitle, InputLabel
} from '@material-ui/core';
import { SnackBar } from '../../snackBars';
import { projectsTypesService } from '../../../services/feathers-rest'

export default class FormEditType extends Component {

  state = {
    projectType: {
      project_phase_id: null
    },
    ShowSnackBar: false,
    message: null,
    variant: 'success'
  }

  componentDidMount() {
    this.setState({ projectType: { project_phase_id: this.props.projectPhaseId } })
  }

  handleChange = (name, e) => {
    this.setState({ projectType: { ...this.state.projectType, [name]: e.target.value, project_phase_id: this.props.projectPhaseId } })
  }

  saveProjectType() {
    projectsTypesService.create(this.state.projectType)
      .then(() => {
        this.setState({ ShowSnackBar: true, message: 'Tipo creado' })
        this.props.closeModalCreateType()
        setTimeout(() => {
          this.setState({ ShowSnackBar: false })
        }, 3000)
        this.setState({ projectType: {} })
        //this.props.load()
      }).catch((e) => {
        this.setState({ ShowSnackBar: true, message: `Tipo no creado mensaje: ${e.message}`, variant: 'error' })
      })
  }

  render() {
    const { openModalCreateType } = this.props;
    const { projectType, ShowSnackBar, message, variant } = this.state;
    return (
      <div>
        <Dialog open={openModalCreateType} onClose={this.props.closeModalCreateType} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Crear tipo</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              {/* <Grid item xs={4}>
                <TextField
                  autoFocus
                  value={projectType.ref_type_id}
                  margin="dense"
                  id="ref_type_id"
                  label="Id tipo"
                  type="number"
                  onChange={(e) => this.handleChange('ref_type_id', e)}
                  fullWidth
                />
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Uso
                  </InputLabel>
                <Select
                  className="width100 margin-t-5"
                  value={projectType.use}
                  onChange={(e) => this.handleChange('use', e)}
                  displayEmpty
                  name="use"
                >
                  <MenuItem value={"Apartamento"}>Apartamento</MenuItem>
                  <MenuItem value={"Casa"}>Casa</MenuItem>
                  <MenuItem value={"Local"}>Local</MenuItem>
                  <MenuItem value={"Bodega"}>Bodega</MenuItem>
                  <MenuItem value={"Oficina"}>Oficina</MenuItem>
                  <MenuItem value={"Otro"}>Otro</MenuItem>
                  <MenuItem value={"Habitaciones"}>Habitaciones</MenuItem>
                </Select>
              </Grid> */}
              <Grid item xs={12}>

                <TextField
                  value={projectType.name}
                  margin="dense"
                  id="name"
                  label="Nombre"
                  type="text"
                  onChange={(e) => this.handleChange('name', e)}
                  fullWidth
                />
                <TextField
                  value={projectType.area_by_type}
                  margin="dense"
                  id="area_by_type"
                  label="Area por tipo"
                  onChange={(e) => this.handleChange('area_by_type', e)}
                  type="number"
                  fullWidth
                />
                <TextField
                  value={projectType.last_price_in_thousands}
                  margin="dense"
                  id="last_price_in_thousands"
                  label="Precio en miles"
                  type="number"
                  onChange={(e) => this.handleChange('last_price_in_thousands', e)}
                  fullWidth
                />
                {/* <TextField
                  value={projectType.last_price_in_m2}
                  autoFocus
                  margin="dense"
                  id="last_price_in_m2"
                  label="Precio en m2"
                  type="text"
                  onChange={(e) => this.handleChange('last_price_in_m2', e)}
                  fullWidth
                /> */}
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Estado
                  </InputLabel>
                <Select
                  className={"width100 margin-t-5"}
                  value={projectType.status}
                  onChange={(e) => this.handleChange('status', e)}
                  displayEmpty
                  name="status"
                >
                  <MenuItem value={"Activo"}>Activo</MenuItem>
                  <MenuItem value={"Desactivado"}>Desactivado</MenuItem>
                </Select>
              </Grid>
              {/* <Grid item xs={4}>
                <TextField
                  value={projectType.unit_by_type}
                  autoFocus
                  margin="dense"
                  id="unit_by_type"
                  label="Unidades por tipo"
                  onChange={(e) => this.handleChange('unit_by_type', e)}
                  type="number"
                  fullWidth
                />
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Proposito
                  </InputLabel>
                <Select
                  className="width100 margin-t-5"
                  value={projectType.purpose}
                  onChange={(e) => this.handleChange('purpose', e)}
                  displayEmpty
                  name="purpose"
                >
                  <MenuItem value={"Venta"}>Venta</MenuItem>
                  <MenuItem value={"Arrendar"}>Arrendar</MenuItem>
                </Select>
              </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModalCreateType} color="primary">
              Cerrar
            </Button>
            <Button onClick={() => this.saveProjectType()} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        <SnackBar ShowSnackBar={ShowSnackBar} message={message} variant={variant} closeSnackbar={() => this.setState({ ShowSnackBar: false })} />
      </div>
    );
  }
}