import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  DialogTitle,
  InputLabel,
} from "@material-ui/core";
import {
  projectsTypesService,
  projectsPhasesService,
} from "../../../services/feathers-rest";
import { SnackBar } from "../../snackBars";
import { S3File } from "../../s3/index";

export default function FormEditType({
  projectTypeId,
  openModalEditType,
  closeModalEditType,
  load,
  projectPhaseId,
  fecthProjectTypes,
}) {
  const [state, setState] = useState({
    projectType: {
      id: "",
      ref_type_id: "",
      name: "",
      last_price_in_thousands: "",
      last_price_in_m2: "",
      unit_by_type: "",
      area_by_type: "",
      use: "",
      status: "",
      purpose: "",
    },
    ShowSnackBar: false,
    message: "",
    variant: null,
    projectPhase: {
      project_id: 5,
      id: null,
    },
  });

  const handleChange = (name, e) => {
    setState({
      projectPhase: state.projectPhase,
      projectType: Object.assign({}, state.projectType, {
        [name]: e.target.value,
      }),
    });
  };

  useEffect(() => {
    fetchTypePhase();
  }, [projectTypeId]);

  const fetchTypePhase = async () => {
    const projectType = await projectsTypesService
      .find({ query: { id: projectTypeId }, paginate: false })
      .then((it) => it.data[0]);
    if (projectType) {
      const projectPhase = await projectsPhasesService
        .find({ query: { id: projectPhaseId }, paginate: false })
        .then((it) => it.data[0]);
      if (projectPhase) setState({ projectPhase, projectType });
    }
  };

  const saveProjectType = async () => {
    await projectsTypesService
      .patch(state.projectType.id, state.projectType)
      .then(() => load(), closeModalEditType())
      .catch((e) =>
        setState({
          message: `Error al guardar ${e.message}`,
          ShowSnackBar: true,
          variant: "error",
          projectType: Object.assign({}, state.projectType, {}),
        })
      );
    fecthProjectTypes();
  };

  const handleUploadFinishProject = async (url) => {
    await projectsTypesService.patch(state.projectType.id, { image_path: url });
    fetchTypePhase();
  };

  return (
    <div>
      <Dialog
        open={openModalEditType}
        onClose={closeModalEditType}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        // style={{ width: '60%' }}
      >
        <DialogTitle id="form-dialog-title">Editar tipo</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="ref_type_id"
                label="Id tipo"
                type="number"
                value={state.projectType.ref_type_id}
                onChange={(e) => handleChange("ref_type_id", e)}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                id="name"
                label="Nombre"
                type="text"
                value={state.projectType.name}
                onChange={(e) => handleChange("name", e)}
                fullWidth
              />
              <TextField
                margin="dense"
                id="area_by_type"
                label="Area por tipo"
                type="number"
                value={state.projectType.area_by_type}
                onChange={(e) => handleChange("area_by_type", e)}
                fullWidth
              />
              <TextField
                margin="dense"
                id="last_price_in_thousands"
                label="Precio en miles"
                type="number"
                value={state.projectType.last_price_in_thousands}
                onChange={(e) => handleChange("last_price_in_thousands", e)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              {state.projectType ? (
                <S3File
                  handleUploadFinish={handleUploadFinishProject}
                  idComponent="projectsType"
                  path={`projects-images/${state.projectPhase.project_id}/project-phases/${state.projectPhase.id}/project-types/${state.projectType.id}`}
                />
              ) : null}
              {state.projectType.image_path ? (
                <div style={{ marginTop: 49 }}>
                  <img
                    src={`https://construcaribe.s3.amazonaws.com/${state.projectType.image_path}`}
                    height="145"
                    width="145"
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalEditType} color="primary">
            Cerrar
          </Button>
          <Button onClick={saveProjectType} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        ShowSnackBar={state.ShowSnackBar}
        message={state.message}
        variant={state.variant}
        closeSnackbar={() => {
          setState({
            ShowSnackBar: false,
            projectType: Object.assign({}, state.projectType, {}),
          });
        }}
      />
    </div>
  );
}
