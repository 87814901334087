import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton,
    DeleteButton
} from 'react-admin';
import { Avatar } from '../com';
export default class BlogsList extends Component {
    render() {
        return (
            <List {...this.props} title="Diseño">
                <Datagrid>
                    <TextField source="id" label="ID" />
                    <Avatar label="Banner PC"  source="path_desktop" />
                    <Avatar label="Banner Teléfono"  source="path_mobil" />
                    <TextField source="url" label="Ruta" />
                    <TextField source="view" label="Pagina mostrar" />
                    <TextField source="position" label="Posición a mostrar" />
                    <EditButton label="" />
                    <DeleteButton label="" />
                </Datagrid>
            </List>
        )
    }
};