import React, { Component, Fragment } from "react";
import {
  Card,
  CardMedia,
  CardActions,
  Grid,
  CardHeader,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  TextField
} from "@material-ui/core";
import { S3File } from "../../s3";
import {
  projectsService,
  projectsImagesService
} from "../../../services/feathers-rest";
import { SnackBar } from "../../snackBars";
import { DeleteIcon } from "../../icons";

let url = "assets/utils/image-not-found/image%20%288%29.png";

const data = [
  { path: url, author: "E-me" },
  { path: url, author: "E-me" },
  { path: url, author: "E-me" },
  { path: url, author: "E-me" }
];

export default class ProjectsImages extends Component {
  state = {
    project: {},
    ShowSnackBar: false,
    Message: "",
    Variant: "error",
    mainmage: false,
    projectsImages: [],
    priority: null,
    disallowActios: false
  };

  async componentDidMount() {
    this.fetchPojects();
  }

  fetchPojects = async () => {
    await projectsService
      .find({ query: { id: this.props.id }, paginate: false })
      .then(res => {
        this.setState({ project: res.data[0] });
      });

    await projectsImagesService
      .find({
        query: { project_id: this.props.id, $sort: { priority: -1 } },
        paginate: false
      })
      .then(res => {
        this.setState({
          projectsImages: res.data.length > 0 ? res.data : data,
          disallowActios: res.data.length > 0 ? true : false
        });
      });
  };

  handleUploadFinishProject = async urls => {
    try {
      await projectsService.patch(this.props.id, { image: urls });
      this.fetchPojects();
      this.setState({
        ShowSnackBar: true,
        Variant: "success",
        Message: "Imagen subida"
      });
    } catch (error) {
      this.setState({
        ShowSnackBar: true,
        Variant: "error",
        Message: error.message
      });
    }
  };

  handleUploadFinishProjectLogo = async urls => {
    try {
      await projectsService.patch(this.props.id, { path_logo: urls });
      this.fetchPojects();
      this.setState({
        ShowSnackBar: true,
        Variant: "success",
        Message: "Imagen subida"
      });
    } catch (error) {
      this.setState({
        ShowSnackBar: true,
        Variant: "error",
        Message: error.message
      });
    }
  };

  handleUploadFinishProjectsImage = async urls => {
    try {
      await projectsImagesService.create({
        project_id: this.props.id,
        path: urls,
        deletedAt: -1
      });
      this.fetchPojects();
      this.setState({
        ShowSnackBar: true,
        Variant: "success",
        Message: "Imagen subida"
      });
    } catch (error) {
      this.setState({
        ShowSnackBar: true,
        Variant: "error",
        Message: error.message
      });
    }
  };

  saveProject = async (priority, id) => {
    await projectsImagesService.patch(id, { priority });
    this.fetchPojects();
  };

  handleChange(event, i) {
    this.state.projectsImages[i].priority = event.target.value;
    this.setState({});
  }

  deleteImageGalery = async projectImageId => {
    await projectsImagesService.remove(projectImageId);
    this.fetchPojects();
  };

  render() {
    const {
      project,
      ShowSnackBar,
      Message,
      Variant,
      projectsImages,
      disallowActios
    } = this.state;
    url = project.image ? project.image : url;
    const logo = project.path_logo ? project.path_logo : url;
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card className="box-shadow-0">
              <CardHeader title="Logo del proyecto" />
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                image={`https://construcaribe.s3.amazonaws.com/${logo}`}
                title="Contemplative Reptile"
              />
              <CardActions>
                <S3File
                  handleUploadFinish={this.handleUploadFinishProjectLogo}
                  idComponent="logo"
                  path={"projects-images"}
                />
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <Card className="box-shadow-0">
              <CardHeader title="imagen principal" />
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                image={`https://construcaribe.s3.amazonaws.com/${url}`}
                title="Contemplative Reptile"
              />
              <CardActions>
                <S3File
                  handleUploadFinish={this.handleUploadFinishProject}
                  idComponent="main"
                  path={"projects-images"}
                />
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <div className="root-grid-list">
              <GridList cellHeight={180} className="gridList">
                <GridListTile
                  key="Subheader"
                  cols={2}
                  style={{ height: "auto" }}
                >
                  <div
                    style={{
                      display: "flex",
                      "justify-content": "space-between"
                    }}
                  >
                    <div>Galeria de Imagenes</div>
                    <div>
                      <S3File
                        handleUploadFinish={
                          this.handleUploadFinishProjectsImage
                        }
                        idComponent="galery"
                        path={"projects-images"}
                      />
                    </div>
                  </div>
                </GridListTile>
                {projectsImages.map((projectImages, i) => (
                  <GridListTile key={projectImages.id}>
                    <img
                      src={`https://construcaribe.s3.amazonaws.com/${projectImages.path}`}
                      alt={projectImages.path}
                    />
                    {disallowActios ? (
                      <GridListTileBar
                        subtitle={
                          <span>prioridad: {projectImages.priority}</span>
                        }
                        actionIcon={
                          <Fragment>
                            <TextField
                              color="white"
                              id="standard-name"
                              placeholder="Prioridad"
                              className="text-field--white"
                              value={projectImages.priority}
                              onBlur={() =>
                                this.saveProject(
                                  projectImages.priority,
                                  projectImages.id
                                )
                              }
                              type="number"
                              onChange={e => this.handleChange(e, i)}
                            />
                            <IconButton aria-label="delete" color="primary">
                              <DeleteIcon
                                fontSize="small"
                                style={{ backgroundColor: "white" }}
                                onClick={() =>
                                  this.deleteImageGalery(projectImages.id)
                                }
                              />
                            </IconButton>
                          </Fragment>
                        }
                      />
                    ) : null}
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </Grid>
        </Grid>
        <SnackBar
          ShowSnackBar={ShowSnackBar}
          message={Message}
          variant={Variant}
          closeSnackbar={() => this.setState({ ShowSnackBar: false })}
        />
      </div>
    );
  }
}
