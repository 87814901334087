import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
const { Option } = Select;

const SelectField = ({
    reference,
    source,
    name,
    onSearch,
    choices,
    optionValue = 'id',
    optionText = 'name',
    ...props
}) => {
    const [dataSource, setDataSource] = useState(choices);
    const [value, setValue] = useState();

    useEffect(() => {
        setDataSource(choices);
    }, [choices]);

    const handleSelect = (value) => {
        setValue(value);
        if (value && props.onChange) props.onChange(value);
    };
    const handleChange = (value) => {
        setValue(value);
        if (!value) {
        if (props.onClear) props.onClear(source || name);
        }
    };
    return (
        <div className="container-selectFields">
            <Select
                style={props.style}
                showSearch={true}
                allowClear={typeof value != 'undefined'}
                onSelect={handleSelect}
                onChange={handleChange}
                placeholder={props.placeholder}
                value={value || props.initialValue}
            >
            {dataSource.map((it, index) => (
            <Option key={index} value={it && it[optionValue || 'id']}>
                {it && typeof optionText == 'function'
                ? optionText(it)
                : it[optionText || 'name']}
            </Option>
            ))}
        </Select>
    </div>
    )
}

export default SelectField;