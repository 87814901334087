import React, { Component } from 'react';
import { projectsTypesService } from '../../services/feathers-rest';

export default class ProjectTypesShow extends Component {

  state = {
    load: true,
    projectsTypes: []
  }

  async componentDidUpdate() {
    if (this.state.load) {
      const a = await projectsTypesService.find({ query: { project_phase_id: this.props.id }, paginate: false })
      this.setState({ projectsTypes: a.data, load: false })
    }
  }

  render() {
    const { projectsTypes } = this.state;
    // console.log(projectsTypes,'-------')
    return (
      <h1>Hola</h1>
    )
  }
}