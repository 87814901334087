import React, { Component } from 'react';
import {
    List, Datagrid, TextField, Filter,
    EditButton, TextInput, Link, DeleteButton
} from 'react-admin';

import { CardMembership } from '@material-ui/icons';

const CompanyField = ({ record, ...rest }) => {
    return record && record.construction_company
        ? (<Link to={`/construction-companies/${record.construction_company.id}`} >
            <TextField source="construction_company.name" record={record} {...rest} />
        </Link>)
        : <Link to={`users/${record.id}`} > Asignar</Link >;
}

const MembershipField = ({ record, ...rest }) => {
    return record && record.affiliate == 'true'
        ? <CardMembership color="primary" />
        : <CardMembership color="secondary" />
}


const FullNameField = ({ source, record = {} }) => {
    return (
        `${record.first_name} ${record.last_name}`
    );
};


const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nombre - email" source="name" alwaysOn />
    </Filter>
);

MembershipField.defaultProps = { label: 'Miembro' }
CompanyField.defaultProps = { label: 'Constructura' };
FullNameField.defaultProps = { label: 'Nombre' };

export default class UsersList extends Component {
    render() {
        return (
            <List {...this.props} filters={<UserFilter />}>
                <Datagrid>
                    <TextField label="Id" source="id" title="Listado de usuarios" />
                    <FullNameField />
                    <TextField source="email" label="Correo" />
                    <TextField source="phone_number" label="Telefono" />
                    <TextField source="company_nit" label="Nit de empresa" />
                    <TextField source="role" label="Rol" />
                    <CompanyField />
                    <MembershipField />
                    <EditButton label="" />
                    <DeleteButton label="" />
                </Datagrid>
            </List>
        )
    }
};