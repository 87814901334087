import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { FileField } from "../fileupload/";
import { getService } from "../../services/";
import { Input, message } from "antd";
import SelectField from '../../common/select';


const view = [
    { value: 'projects', description: 'Proyectos' },
    { value: 'home', description: 'Pagina Principal' },
    { value: 'news', description: 'Noticias' },
];
class DesignEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path_desktop: '',
            path_mobil: '',
            progress: false,
            url: '',
            view: '',
            id_image: '',
            id: '',
            position: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.getData = this.getData.bind(this);
    }


    getData() {
        getService('design').get(this.props.id)
            .then(response => {
                this.setState(response)
            }).catch(
                err => console.log(err)
            )
    }

    componentDidMount() {
        this.getData();
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        })
    }

    handleSelect(value, name) {
        this.setState({
            ...this.state,
            [name]: value
        })
    }

    showLoader = (progress) => {
        this.setState({ progress, })
    }

    changeImage = (url, progress) => {
        this.setState({ path_desktop: url, progress, path_mobil: url })
    }

    handleUploadFinish = (field, url, file, _id, e) => {
        this.setState({ [field]: { file, url } })
    };

    createBanner = () => {
        const service = getService('design');
        service.patch(this.state.id, {
            path_desktop: this.state.path_desktop.url,
            path_mobil: this.state.path_mobil.url,
            url: this.state.url,
            view: this.state.view,
            position: this.state.position,
        }).then(response => {
            message.success("Banner Actualizado!");
            this.props.history.push('/design')
        })
            .catch(err => message.error(err.message));
    }

    // handleUploadFinish = (field, url, file, _id) => {
    //   const service = getService('design');
    //   if (_id)
    //     return service.patch(_id, {
    //       [field]: url,
    //         url: this.state.url,
    //     }).then(response => {
    //       message.success("Foto Actualizada!");
    //     })
    //       .catch(err => message.error(err.message));
    // };

    render() {
        const { path_desktop, path_mobil, progress } = this.state;
        let image;
        if (progress) {
            image = <CircularProgress className="progress" />
        } else {
            image = <img src={`https://construcaribe.s3.amazonaws.com/${path_desktop}`} className="margin-left-40px" />
            image = <img src={`https://construcaribe.s3.amazonaws.com/${path_mobil}`} className="margin-left-40px" />
        }

        return (
            <div style={{
                height: 'auto',
                minHeight: 150,
                backgroundColor: '#fff',
                width: '100%',
                padding: '5px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                alignItem: 'center',
                gap: 15,
                justifyContent: 'center',
                border: '1px solid #e9e9e9'
            }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 4 }}>
                    <span style={{ fontSize: 12 }}>URL</span>
                    <Input
                        title='URL'
                        name='url'
                        style={{ height: 32, width: '100%' }}
                        value={this.state.url}
                        onChange={this.handleChange}
                        placeholder="URL"
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 4 }}>
                    <span style={{ fontSize: 12 }}>Tipo de Banner</span>
                    <SelectField
                        style={{ width: '100%' }}
                        placeholder="Tipo de Banner"
                        choices={view}
                        name="view"
                        optionText='description'
                        optionValue='value'
                        initialValue={this.state.view}
                        onChange={(e) => this.handleSelect(e, 'view')}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 4 }}>
                    <span style={{ fontSize: 12 }}>Posición</span>
                    <SelectField
                        style={{ width: '100%' }}
                        placeholder="Posición"
                        choices={[
                            { value: 'top', description: 'Arriba' },
                            { value: 'left', description: 'Izquierda' },
                        ]}
                        name="position"
                        optionText='description'
                        optionValue='value'
                        initialValue={this.state.position}
                        onChange={(e) => this.handleSelect(e, 'position')}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItem: 'center', width: '100%', justifyContent: 'center', gap: 7 }}>
                    <FileField
                        componentId="path_desktop"
                        source="path_desktop"
                        label='Subir imagen PC'
                        path="desing"
                        name="path_desktop"
                        onFinish={(url, file, id, e) => this.handleUploadFinish("path_desktop", url, file, id, e)}
                    />
                    <span style={{ width: '100%', textAlign: 'center' }}>{this.state.position === 'left' ? 'Se recomienda esta resolución 245 × 1160' : 'Se recomienda esta resolución 1920 × 640'}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItem: 'center', width: '100%', justifyContent: 'center', gap: 7 }}>
                    <FileField
                        label='Subir imagen Teléfono'
                        componentId="path_mobil"
                        source="path_mobil"
                        path="desing"
                        name="path_mobil"
                        onFinish={(url, file, id, e) => this.handleUploadFinish("path_mobil", url, file, id, e)}
                    />
                    <span style={{ width: '100%', textAlign: 'center' }}>Se recomienda esta resolución 425 x 482 </span>
                </div>
                <div style={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}>
                    <button style={{ width: '120px', padding: '7px', justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', backgroundColor: '#2196f3', color: '#fff', border: 'none', borderRadius: '4px', fontSize: 15 }}
                        type='button' onClick={() => this.createBanner()}>Editar</button>
                </div>
            </div>
        )
    }
}

export default DesignEdit;