import React, { Component } from 'react'
import {
  Create,
  SimpleForm,
  TextInput
} from 'react-admin'
import Grid from '@material-ui/core/Grid'

export default class ConfigurationsCreate extends Component {
  render() {
    return (
      <Create title="Crear" {...this.props}>
        <SimpleForm>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput fullWidth source="key" label="Clave" />
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth source="value" label="Valor" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextInput label="Descripción" source="description" fullWidth />
          </Grid>
        </SimpleForm>
      </Create>
    )
  }
}