import React, {Component} from 'react';
import {Snackbar, Slide} from '@material-ui/core/';

export default class SnackbarNotification extends Component{

    render(){
        const {ShowSnackBar, message} = this.props
        return(
            <div>
                 <Snackbar
                    open={ShowSnackBar}
                    onClose={this.handleClose}
                    TransitionComponent="TransitionUp"
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{message}</span>}
                />
            </div>
        )
    }

}