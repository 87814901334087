import feathers from '@feathersjs/feathers';
import restFeathers from '@feathersjs/rest-client';
import feathersAuthClient from '@feathersjs/authentication-client';
const { REACT_APP_API_ENDPOINT } = process.env;

// const host = 'http://localhost:3032';

const host = `https://${REACT_APP_API_ENDPOINT}`;

const appFeathers = feathers();
const restClient = restFeathers(host);

const authClient = feathersAuthClient({
    header: 'Authorization',
    path: '/authentication',
    jwtStrategy: 'jwt',
    entity: 'user',
    service: 'users',
    storage: window.localStorage
});

appFeathers.configure(restClient.fetch(window.fetch.bind(window)));
appFeathers.configure(authClient);

export { host };
export default appFeathers;
