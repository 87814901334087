import React,{Component} from 'react';
import { Edit, SimpleForm, TextInput, LongTextInput } from 'react-admin';
import {NeighborhoodTitle} from './'

export default class NeighborhoodsEdit extends Component{
    render(){
        return(
            <Edit title={<NeighborhoodTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" label="Nombre"/>
                    <LongTextInput source="description" label="Descripción" />
                </SimpleForm>
            </Edit>
        )
    }
};