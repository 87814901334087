import { green, orange, yellow } from '@material-ui/core/colors';


const rowStyle = (record, index, defaultStyle = {}) => {
    if (record.status === 'Activo')
        return {
            ...defaultStyle,
            borderLeftColor: green[500],
            borderLeftWidth: 7,
            borderLeftStyle: 'solid',
        };
    if (record.status === 'Inactivo')
        return {
            ...defaultStyle,
            borderLeftColor: orange[500],
            borderLeftWidth: 7,
            borderLeftStyle: 'solid',
        };
    if (record.status === 'En espera de aprobación')
        return {
            ...defaultStyle,
            borderLeftColor: yellow[500],
            borderLeftWidth: 7,
            borderLeftStyle: 'solid',
        };
    return defaultStyle;
};

export default rowStyle;