import React, { Component } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import GenerateExcel from './generateExcel';

export default class ContactList extends Component {

    render() {
        return (
            <>
            <GenerateExcel />
            <List
            filters={false}
            actions={false} 
            bulkActionButtons={false}  
            {...this.props} 
            title="Contactos">
                <Datagrid>
                    <TextField 
                        source="project.construction_company.nit" 
                        label="Nit constructora" 
                    />
                    <TextField source="project.id" label="ID Proyecto" />
                    <TextField source="project.name" label="Proyecto" />
                    <TextField source="full_name" label="Nombre" />
                    <TextField source="email" label="Email" />
                    <TextField source="message" label="Mensaje" />
                </Datagrid>
            </List>
            </>
        )
    }
};