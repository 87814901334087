import UsersList from './lists';
import UsersEdit from './edit';
import UserIcon from '@material-ui/icons/Person';
import UsersCreate from './create';
import React from 'react';

const UserTitle = ({ record }) => {
    return <span>Editando al Usuario {record ? `${record.first_name} ${record.last_name}` : ''}</span>;
};

export const affiliate_status = [
    {
        id: "true",
        name: "Activo"
    },
    {
        id: "false",
        name: "Inactivo"
    }
]

export { UsersList, UsersEdit, UserIcon, UserTitle, UsersCreate };