import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { projectsCommonZonesService, commonZonesService } from '../../../services/feathers-rest';
import { Grid, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core';
import { DeleteIcon } from '../../icons';
import { IotData } from 'aws-sdk';

export default class ProjectZonesInterestSelect extends Component {
    state = {
        projectCommonZones: [],
        common_zone_id: null,
        journey_time: null,
        commonZones: [
            { value: '', label: '' }
        ]
    };

    componentDidMount() {
        this.fetchCommonZone()
    }

    async fetchCommonZone() {
        const projectCommonZones = await projectsCommonZonesService.find({ query: { project_id: this.props.id }, paginate: false }).then(it => it.data)
        const commonZones = await commonZonesService.find({ query: { id: { $nin: projectCommonZones.map(it => it.common_zone_id) }, $limit: 10000 }, paginate: false }).then(it => it.data)
        this.setState({ projectCommonZones, commonZones: commonZones.map((it) => ({ value: it.id, label: it.name })) })

    }

    handleChange = (name, e) => {
        this.setState({ [name]: e.target.value })
    }

    saveZoneInterest = async () => {
        const params = {
            project_id: this.props.id,
            common_zone_id: this.state.common_zone_id
        };

        await projectsCommonZonesService.create(params)
        this.fetchCommonZone();
    }

    deleteZoneInterest = async (id) => {
        await projectsCommonZonesService.remove(id);
        this.fetchCommonZone();
    }

    render() {
        const { projectCommonZones, commonZones } = this.state;
        return (
            <Fragment>
                <Grid container spacing={7}>
                    <Grid item xs={4}>
                        <div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue=""
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={commonZones}
                                onChange={e => {
                                    if (!e) return;
                                    this.setState({ common_zone_id: e.value })
                                }}
                            />
                        </div>
                        <br></br>
                        <div>
                            <Button
                                onClick={() => this.saveZoneInterest()}
                                color="primary"
                                variant="contained"
                            >
                                guardar
                        </Button>
                        </div>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                    <Grid item xs={4}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell align="right">Eliminar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projectCommonZones.map(commonZone => (
                                    <TableRow key={commonZone.id}>
                                        <TableCell component="th" scope="row">
                                            {commonZone.common_zones.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => this.deleteZoneInterest(commonZone.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}