import { MenuItem, Select } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import appFeathers from '../../connections/feathers'

const BoxContainer = styled.div`
    display:flex;
    width: 100%;
    flex-direction:row;
    align-items:center;
    margin-bottom:15px;
    justify-content:space-between;
    background-color:#fff;
    padding:10px;
    box-shadow:0px 1px 3px 0px rgb(0 0 0 / 15%), 0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 2px 1px -1px rgb(0 0 0 / 10%);
`
const Div = styled.div`
    display:flex;
    width: auto;
    flex-direction:row;
    align-items:center;
    gap:5px;
    justify-content:flex-end;
`
const ButtonPrint = styled.button`
    display:flex;
    height:40px;
    align-items:center;
    justify-content:center;
    border:none;
    border-radius:4px;
    cursor:pointer;
    background-color: #2196f3;
    color:#fff;
    :hover{
        opacity: .8;
    }
`
const Span = styled.span`
    width:auto;
`
const Input = styled.input`
    display:flex;
    height:40px;
    padding:0px 0px 0px 7px;
    align-items:center;
    justify-content:center;
    background-color:#fff;
    border:1px solid #d2d2d2;
    border-radius:4px;
    outline:none;
    :focus{
        border:1px solid #2196f3;
    }
`

const GenerateExcel = () => {

    //Custom state
    const [loadReport, setLoadReport] = useState(false);
    const [data, setData] = useState({
        type: 3,
        nit: '',
        projectId: '',
    });
    

    const getService = async (...args) => {
        const res = await appFeathers.service('contact-projects').find(...args)
        return res
    }

    const report = () => {
        setLoadReport(true)
        const test = {
            1: { company_nit: data.nit, report: 'true' },
            2: { project_id: data.projectId, report: 'true' }
        }

        getService({ query: { $client: test[data.type] || {report: true} } }).then((res) => { 
            window.open(res.url, '_blank')
            setLoadReport(false)
            setData({
                type: 3,
                nit: '',
                projectId: '',
            })
            
        })
        .catch(error => {
            console.log(error)
            setLoadReport(false)
            
        })   
    }

    const handleInputChange = (e) => {
        setData({ 
            ...data,
            [e.target.name]: e.target.value 
        })
    }

    return (
        <BoxContainer>
            <Span>Generar Informe</Span>
            <Div>
                    <span id="demo-simple-select-helper-label">Filtros</span>
                    <Select
                    style={{width: 150, height: '40px'}}
                        labelId="demo-simple-select-helper-label"
                        id="type"
                        value={data.type}
                        label="Filtros"
                        name='type'
                        onChange={handleInputChange}
                        >
                        <MenuItem value={1}>Por Nit Constructora</MenuItem>
                        <MenuItem value={2}>Por ID de Proyecto</MenuItem>
                        <MenuItem value={3}>Todos</MenuItem>
                    </Select>
                {data.type === 1 && <Input 
                    name='nit'
                    value={data.nit} 
                    onChange={handleInputChange} 
                    placeholder='Nit Constructora'  
                />}
                {data.type === 2 &&   <Input 
                    name='projectId'
                    onChange={handleInputChange} 
                    value={data.projectId}  
                    placeholder='Id Proyecto'   
                    />
                }
                <ButtonPrint
                    onClick={report}
                    disabled={loadReport}
                    type='ButtonPrint'
                >
                { loadReport ? 'Generando...' : 'Generar reporte'}
                </ButtonPrint>
            </Div>
        </BoxContainer>
    )
}

export default GenerateExcel