import React, { Component } from 'react';
import { Edit, SimpleForm, NumberInput, SelectInput, TextInput, ImageField } from 'react-admin';
import { Grid } from '@material-ui/core';
import { constructionCompaniesService } from '../../services';
import { S3File } from '../s3';

export default class ConstructionCompanieEdit extends Component {
    state = {
        path_logo: '',
    }

    saveCoverLogo = async urls => {
        const { match } = this.props;
        await constructionCompaniesService
            .patch(match.params.id, {
                logo: `${urls}`
            })
            .then((res) => this.setState({ path_logo: res.logo }));
    };

    componentDidMount = async () => {
        const { match } = this.props;
        await constructionCompaniesService
            .get(match.params.id)
            .then((res) => this.setState({ path_logo: res.logo }));
    }

    render() {
        console.log(this.state.path_logo)
        const status = [
            { value: "Activa", description: "Activa" },
            { value: "Inactiva", description: "Inactiva" },
        ];

        const ConstructionCompanieTitle = ({ record }) => {
            return <span>Compañía con id {record ? `"${record.id}"` : ''}</span>;
        };

        return (
            <Edit title={<ConstructionCompanieTitle />} {...this.props}>
                <SimpleForm>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid>
                                <TextInput source="name" label="Nombre de Empresa" />
                            </Grid>
                            <Grid>
                                <TextInput source="phone" label="Teléfono" /><br></br>
                            </Grid>
                            <Grid>
                                <TextInput source="email" label="Email" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid>
                                <NumberInput source="nit" label="NIT" />
                            </Grid>
                            <Grid>
                                <SelectInput source="status" choices={status} optionText="description" optionValue="value" /><br></br>
                            </Grid>
                            <Grid>
                                <TextInput source="address" label="Dirección" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <Grid item xs={12} spacing={3}>
                                <S3File
                                    handleUploadFinish={this.saveCoverLogo}
                                    service="constructionCompaniesService"
                                    id={this.props.id}
                                    idComponent="main"
                                    path={"logo-company"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <img
                                    src={`https://construcaribe.s3.amazonaws.com/${this.state.path_logo}`}
                                    alt=""
                                    style={{ marginTop: '10px' }}
                                    height="20%"
                                    width="20%"
                                />
                                <ImageField source="logo" title="logo" />
                            </Grid>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}