import React, { Component } from 'react';
import { projectsPhasesService } from '../../../services/feathers-rest';
import FromEditPhases from './form-edit-phase';
import { SnackBar } from '../../snackBars/'
import { Button, Box } from '@material-ui/core';
import ModalCreatePhase from './modal-create-phase';
export default class ProjectPhase extends Component {

  state = {
    load: true,
    projectPhase: [],
    projectPhaseData: {
      name: '',
      id_phase: '',
      use: '',
      number_of_units: '',
      status: '',
      purpose: ''
    },
    ShowSnackBar: false,
    Variant: 'error',
    Message: '',
    openModalCreatePhase: false
  }

  componentDidUpdate() {
    if (this.state.load) {
      projectsPhasesService.find({ query: { project_id: this.props.id }, paginate: false }).then(result => result.data ? this.setState({ projectPhase: result.data, load: false }) : [])
    }
  }

  openModalCreatePhase = () => {
    this.setState({ openModalCreatePhase: true })
  }



  save(projectPhase) {
    projectsPhasesService.patch(projectPhase.id, { ...projectPhase })
      .then(() => {
        this.setState({ ShowSnackBar: true, Variant: 'success', Message: 'Fase actualizadaa' })
        setTimeout(() => {
          this.setState({ ShowSnackBar: false, load: true })
        }, 3000)
        this.componentDidUpdate()
      })
      .catch((err) => { this.setState({ ShowSnackBar: true, Variant: 'error', Message: err.message }) })
  }

  render() {
    const { projectPhase, ShowSnackBar, Variant, Message } = this.state
    return (
      <div>
        <Box display="flex" justifyContent="flex-end" m={1} p={1} bgcolor="background.paper">
          <Button
            onClick={this.openModalCreatePhase}
            variant="contained"
            color="primary"
          >
            Crear
              </Button>
          <ModalCreatePhase openModalCreatePhase={this.state.openModalCreatePhase} onClosed={() => this.setState({ openModalCreatePhase: false })} idProject={this.props.id} componentDidUpdate={() => this.componentDidUpdate()} load={() => { this.setState({ load: true }) }} />
        </Box>
        <div>
          {projectPhase.map(phase => (
            <div>
              <FromEditPhases phase={phase} save={(values) => this.save(values)} />
            </div>
          ))}
          <div>
            <SnackBar ShowSnackBar={ShowSnackBar} variant={Variant} message={Message} closeSnackbar={() => this.setState({ ShowSnackBar: false })} />
          </div>
        </div>
      </div>
    )
  }
}