import React, { Component } from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

export default class CommonZonestList extends Component {
    render() {
        return (
            <List {...this.props}>
                <Datagrid>
                    <TextField source="name" label="Nombre" />
                    <EditButton label="" />
                </Datagrid>
            </List>
        )
    }
};