import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { Grid } from "@material-ui/core";

export default (props) => {
  return (
    <Create title={"Crear Revista"} {...props}>
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput source="name" label="Nombre" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="link" label="Link" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="position" label="Prioridad" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
