import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput } from 'react-admin';
import { Grid } from '@material-ui/core';  
  
class ProjectPhaseEdit extends Component{
    
    render(){

        const statuses = [
          { _id: 'ABIERTA', description: 'Abierta' },
          { _id: 'CERRADA', description: 'Cerrada' }
        ];

        const purposes = [
          {_id:'Venta', description:'Venta'},
          {_id:'Arrendar', description:'Arrendar'}
        ];

        const use = [
          {_id:'Apartamento', description:'Apartamento'},
          {_id:'Casa', description:'Casa'},
          {_id:'Local', description:'Local'},
          {_id:'Bodega', description:'Bodega'},
          {_id:'Oficina', description:'Oficina'},
          {_id:'Habitaciones', description:'Habitaciones'},
        ]; 

        return(
          <Edit title={'Crear Blog'} {...this.props}>
            <SimpleForm>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput source="name" label="Nombre"/><br/>
                  <TextInput source="ref_id_phase" label="Id fase"/><br/>
                  <TextInput  source="ref_code_phase" label="Codigo fase"/><br/>
                  <NumberInput source="number_of_units" label="Unidades"/><br/>
                </Grid>
                <Grid item xs={6}>
                  <SelectInput source="status" choices={statuses} optionText="description" optionValue="_id" label="Estado"/><br/>
                  <SelectInput source="purpose" choices={purposes} optionText="description" optionValue="_id" label="Proposito"/><br/>
                  <SelectInput source="use" choices={use} optionText="description" optionValue="_id" label="Uso"/><br/>
                </Grid>
              </Grid>
            </SimpleForm>   
          </Edit>
        )
    }
}

export default ProjectPhaseEdit;