import React, { Component } from "react";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  TextareaAutosize,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import {
  projectsService,
  locationsCitiesService,
  zoneService,
  constructionCompaniesService,
  neighborhoodService,
} from "../../../services/feathers-rest";
import { SnackBar } from "../../snackBars/";
import { message } from "antd";
export default class projectMain extends Component {
  state = {
    value: 0,
    project: {
      id: "",
      name: "",
      ref_project_id: "",
      ref_project_code: "",
      lat: "",
      long: "",
      address: "",
      phone: "",
      number_of_units: "",
      number_of_blocks: "",
      fiduciary_entity: "",
      _geoloc: {
        lat: "",
        lng: "",
      },
      priority: 0,
    },
    id: null,
    locationsCities: [],
    zones: [],
    constructionCompanies: [],
    neighborhoods: [],
    ShowSnackBar: false,
    Message: "",
    Variant: "error",
    edited_by: false,
  };

  handleChange = (name, e) => {
    this.setState({
      project: Object.assign({}, this.state.project, {
        [name]: e.target.value,
      }),
    });
  };

  closeSnackbarTime(time) {
    setTimeout(() => {
      this.setState({ ShowSnackBar: false });
    }, time);
  }

  saveProject = async () => {
    const { project } = this.state;
    console.log(project, "--------------------");
    await projectsService
      .patch(this.state.project.id, project)
      .then((result) => {
        const Message =
          result.status == "Activo"
            ? "Datos basicos del proyecto actualizados y subido"
            : "Datos basicos del proyecto actualizados";
        const Variant = result.status == "Activo" ? "success" : "warning";
        this.setState({ ShowSnackBar: true, Message, Variant });
        this.closeSnackbarTime(3000);
        this.componentDidMount();
      })
      .catch((err) => {
        this.setState({
          ShowSnackBar: true,
          Message: err.message,
          Variant: "error",
        });
      });
  };

  handleChangeEditedBy = async (name, event) => {
    await this.setState({ [name]: event.target.checked });

    this.state.edited_by
      ? this.setState({ edited_by: "No editado" })
      : this.setState({ edited_by: "Administrador" });
    const project = await projectsService.patch(this.state.project.id, {
      edited_by: this.state.edited_by,
    });

    this.setState({ edited_by: project.edited_by });
    this.fecthEditedBy();
  };

  fecthEditedBy() {
    if (this.state.edited_by !== "No editado")
      this.setState({ edited_by: false });
  }

  async componentDidMount() {
    this.fecthProject();
  }

  componentDidUpdate() {
    if (this.props.loadProject) {
      this.fecthProject();
      this.props.loadProjectFalse();
    }
  }

  fecthProject = async () => {
    const { id } = this.props;
    try {
      const [
        project,
        locationsCities,
        zones,
        constructionCompanies,
        neighborhoods,
      ] = await Promise.all([
        projectsService
          .find({ query: { id: id } })
          .then((result) => result.data[0]),
        locationsCitiesService
          .find({ query: { $limit: 1000 } })
          .then((result) => result.data),
        zoneService
          .find({ query: { $limit: 1000 } })
          .then((result) => result.data),
        constructionCompaniesService
          .find({ query: { $limit: 1000 } })
          .then((result) => result.data),
        neighborhoodService
          .find({ query: { $limit: 1000 } })
          .then((result) => result.data),
      ]);
      await this.setState({
        project: project,
        locationsCities: locationsCities,
        zones: zones,
        constructionCompanies: constructionCompanies,
        neighborhoods: neighborhoods,
        edited_by: project.edited_by,
      });
      this.fecthEditedBy();
    } catch (err) {
      message.error(err.message);
    }
  };

  render() {
    const {
      project,
      locationsCities,
      zones,
      constructionCompanies,
      neighborhoods,
      ShowSnackBar,
      Message,
      Variant,
      edited_by,
    } = this.state;
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <div style={{ backgroundColor: "#fafafa", padding: 4 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={edited_by}
                    onChange={(e) => this.handleChangeEditedBy("edited_by", e)}
                    value="Editardo"
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    label="Secondary"
                  />
                }
                label={
                  edited_by
                    ? "sincronización por Coordenada Urbana Activa"
                    : "Actualización manual activa"
                }
              />
              <div style={{ fontSize: 10 }}>
                <p>
                  Al activar todos los dato serán sincronizados por el excel a
                  exepción de:
                </p>
                <ul>
                  <li>
                    Imagenes, zonas comunes, zonas de interes, descripción,
                    dirección, email vendedora.
                  </li>
                </ul>
              </div>
            </div>
            <TextField
              label="Identificador"
              fullWidth
              value={project.ref_project_id}
              onChange={(e) => this.handleChange("ref_project_id", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Nombre del proyecto"
              fullWidth
              value={project.name}
              onChange={(e) => this.handleChange("name", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Codigo"
              fullWidth
              value={project.ref_project_code}
              onChange={(e) => this.handleChange("ref_project_code", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Latitud"
              fullWidth
              value={project._geoloc.lat}
              onChange={(e) => this.handleChange("lat", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Longitud"
              fullWidth
              value={project._geoloc.lng}
              onChange={(e) => this.handleChange("long", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Dirección"
              fullWidth
              value={project.address}
              onChange={(e) => this.handleChange("address", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Telefono"
              fullWidth
              value={project.phone}
              onChange={(e) => this.handleChange("phone", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Fiduciaria"
              fullWidth
              value={project.fiduciary_entity}
              onChange={(e) => this.handleChange("fiduciary_entity", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Numero de unidades"
              fullWidth
              value={project.number_of_units}
              onChange={(e) => this.handleChange("number_of_units", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Numero de bloques"
              fullWidth
              value={project.number_of_blocks}
              onChange={(e) => this.handleChange("number_of_blocks", e)}
              margin="normal"
            />
            <br />
            <TextField
              label="Destacado"
              fullWidth
              value={project.priority}
              onChange={(e) => this.handleChange("priority", e)}
              margin="normal"
            />
            <br />
            <InputLabel
              className="margin-t-20"
              style={
                project.status == "Activo"
                  ? { color: "green" }
                  : { color: "red" }
              }
            >
              Estado
            </InputLabel>
            <Select
              className="margin-b-20 width100"
              value={project.status}
              onChange={(e) => this.handleChange("status", e)}
            >
              <MenuItem value="Activo">Activo</MenuItem>
              <MenuItem value="Inactivo">Inactivo</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="margin-t-20">Ciudad</InputLabel>
            <Select
              className="margin-b-20 width100"
              value={project.city_id}
              onChange={(e) => this.handleChange("city_id", e)}
            >
              {locationsCities.map((city) => {
                return <MenuItem value={city.id}>{city.name}</MenuItem>;
              })}
            </Select>
            <InputLabel className="margin-t-5">Zona</InputLabel>
            <Select
              className="margin-b-20 width100"
              value={project.zone_id}
              onChange={(e) => this.handleChange("zone_id", e)}
            >
              {zones.map((zone) => {
                return <MenuItem value={zone.id}>{zone.name}</MenuItem>;
              })}
            </Select>
            <InputLabel className="margin-t-5 width100">
              Constructora
            </InputLabel>
            <Select
              className="margin-b-20 width100"
              value={project.construction_company_id}
              onChange={(e) => this.handleChange("construction_company_id", e)}
            >
              {constructionCompanies.map((company) => {
                return <MenuItem value={company.id}>{company.name}</MenuItem>;
              })}
            </Select>
            <InputLabel className="margin-t-5">Barrio</InputLabel>
            <Select
              className="margin-b-20 width100"
              value={project.neighborhood_id}
              onChange={(e) => this.handleChange("neighborhood_id", e)}
            >
              {neighborhoods.map((neighborhood) => {
                return (
                  <MenuItem value={neighborhood.id}>
                    {neighborhood.name}
                  </MenuItem>
                );
              })}
            </Select>
            <InputLabel className="margin-t-5">Tipo de uso</InputLabel>
            <Select
              className="margin-b-20 width100"
              value={project.general_use}
              onChange={(e) => this.handleChange("general_use", e)}
            >
              <MenuItem value="Residencial">Residencial</MenuItem>
              <MenuItem value="No residencial">No residencial</MenuItem>
            </Select>
            <InputLabel className="margin-t-5">Estrato</InputLabel>
            <Select
              className="margin-b-20 width100"
              value={project.stratus}
              onChange={(e) => this.handleChange("stratus", e)}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
            </Select>
            {/* <InputLabel className="margin-t-5">Tipo de vivienda</InputLabel> */}
            <br />
            <TextField
              label="Tipo de vivienda"
              fullWidth
              value={project.type_living_place}
              onChange={(e) => this.handleChange("type_living_place", e)}
              margin="normal"
            />
            {/* <Select
              className="margin-b-20 width100"
              value={project.type_living_place}
              onChange={(e) => this.handleChange("stratus", e)}
            >
              <MenuItem value="No VIS">No vis</MenuItem>
              <MenuItem value="SIN ASIGNAR">Sin asignar</MenuItem>
              <MenuItem value="VIS">Vis</MenuItem>
            </Select> */}
            <br />
            <InputLabel className="margin-t-5">
              Descripción del proyecto
            </InputLabel>
            <TextareaAutosize
              className="width100"
              value={project.project_description}
              aria-label="minimum height"
              rows={9}
              placeholder="Descripción del proyecto"
              onChange={(e) => this.handleChange("project_description", e)}
            />
            <InputLabel className="margin-t-5">
              Descripción de la Dirección
            </InputLabel>
            <TextareaAutosize
              className="width100"
              value={project.address_description}
              aria-label="minimum height"
              rows={9}
              placeholder="Descripción de las dirección"
              onChange={(e) => this.handleChange("address_description", e)}
            />
            <InputLabel className="margin-t-5">
              Descripción de las zonas comunes
            </InputLabel>
            <TextareaAutosize
              className="width100"
              value={project.zones_interest_description}
              aria-label="minimum height"
              rows={3}
              placeholder="Descripción de las zonas comunes"
              onChange={(e) =>
                this.handleChange("zones_interest_description", e)
              }
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.saveProject()}
            >
              Guardar
            </Button>
            <SnackBar
              ShowSnackBar={ShowSnackBar}
              message={Message}
              variant={Variant}
              closeSnackbar={() => this.setState({ ShowSnackBar: false })}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
