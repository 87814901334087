import React, { Component } from 'react';
import { Snackbar, Slide } from '@material-ui/core/';
import { EditIcon } from '../icons'
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: 'green'
    },
    warning: {
        backgroundColor: '#ADB202'
    },
    error: {
        backgroundColor: 'red',
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));


function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { message, variant, closeSnackbar } = props
    return (
        <SnackbarContent
            className={clsx(classes[variant])}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar">
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" >
                    <CloseIcon
                        onClick={closeSnackbar}
                    />
                </IconButton>,
            ]}
        />
    );
}


export default class SnackbarNotification extends Component {

    render() {
        const { ShowSnackBar, message, variant, closeSnackbar } = this.props
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={ShowSnackBar}
                    autoHideDuration={6000}
                    onClose={ShowSnackBar}
                >
                    <MySnackbarContentWrapper
                        variant={variant ? variant : 'success'}
                        message={message}
                        closeSnackbar={closeSnackbar}
                    />
                </Snackbar>
            </div>
        )
    }

}