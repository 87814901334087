import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Divider,
  Grid,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { projectsTypesService } from "../../../services/feathers-rest";
import { EditIcon, FeaturedPlayListIcon, DeleteIcon } from "../../icons";
import FormEditType from "./form-edit-type";
import FormCreateType from "./form-create-type";
import FormCreateFeature from "./form-create-feature";
export default class FormEditPhase extends Component {
  state = {
    projectPhase: {
      id: null,
      name: "",
      ref_id_phase: "",
      status: "",
      use: "",
      number_of_units: "",
      phase: "",
    },
    projectTypes: [],
    openModalEditType: false,
    openModalCreateFeature: false,
    openModalCreateType: false,
    currentProjectTypeId: null,
  };

  componentDidMount() {
    this.setState({ projectPhase: this.props.phase });
    this.fecthProjectTypes();
  }

  handleChange = (name, e) => {
    this.setState({
      projectPhase: Object.assign({}, this.state.projectPhase, {
        [name]: e.target.value,
      }),
    });
  };

  fecthProjectTypes() {
    projectsTypesService
      .find({
        query: { project_phase_id: this.props.phase.id },
        paginate: false,
      })
      .then((result) => this.setState({ projectTypes: result.data }));
  }

  closeModalEditType = () => {
    this.setState({ openModalEditType: false });
  };

  deleteType = async (projectTypeId) => {
    await projectsTypesService
      .remove(projectTypeId)
      .then((it) => this.componentDidMount());
  };

  render() {
    const { projectPhase, projectTypes } = this.state;
    return (
      <div>
        <ExpansionPanel defaultExpanded style={{ boxShadow: "none" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div>
              <Typography variant="subtitle1">Nombre:</Typography>
            </div>
            <div>
              <Typography variant="subtitle1">{`${this.props.phase.name}`}</Typography>
            </div>
            <div className="padding-left-35">
              <Typography variant="subtitle1">Referencia:</Typography>
            </div>
            <div>
              <Typography variant="subtitle1">{`${this.props.phase.ref_id_phase}`}</Typography>
            </div>
            <div className="padding-left-35">
              <Typography variant="subtitle1">Estado:</Typography>
            </div>
            <div>
              <Typography
                className={`${
                  this.props.phase.status == "ABIERTA"
                    ? "statusTrue"
                    : "statusFalse"
                }`}
                variant="subtitle1"
              >{`${this.props.phase.status}`}</Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div>
                  <TextField
                    className="width100"
                    id="standard-name"
                    label="Nombre"
                    value={projectPhase.name}
                    onChange={(e) => this.handleChange("name", e)}
                    margin="normal"
                  />
                </div>
                <div>
                  <TextField
                    className="width100"
                    id="standard-name"
                    label="Id Fase"
                    value={projectPhase.ref_id_phase}
                    onChange={(e) => this.handleChange("ref_id_phase", e)}
                    margin="normal"
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ "margin-top": 16 }}>
                  <InputLabel>Uso</InputLabel>
                  <Select
                    className="width100"
                    value={projectPhase.use}
                    onChange={(e) => this.handleChange("use", e)}
                    inputProps={{
                      name: "purpose",
                      id: "age-simple",
                    }}
                  >
                    <MenuItem value={"Apartamento"}>Apartamento</MenuItem>
                    <MenuItem value={"Casa"}>Casa</MenuItem>
                    <MenuItem value={"Bodega"}>Bodega</MenuItem>
                    <MenuItem value={"Oficina"}>Oficina</MenuItem>
                    <MenuItem value={"Habitaciones"}>Habitaciones</MenuItem>
                  </Select>
                </div>
                <div>
                  <TextField
                    style={{ marginTop: 24 }}
                    className="width100"
                    label="Numero de Unidades"
                    value={projectPhase.number_of_units}
                    onChange={(e) => this.handleChange("number_of_units", e)}
                    margin="normal"
                    type="number"
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ "margin-top": 16 }}>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    className="width100 "
                    value={projectPhase.status}
                    onChange={(e) => this.handleChange("status", e)}
                    inputProps={{
                      name: "status",
                      id: "age-simple",
                    }}
                  >
                    <MenuItem value={"ABIERTA"}>Abierta</MenuItem>
                    <MenuItem value={"CERRADA"}>Cerrada</MenuItem>
                  </Select>
                </div>
                <br></br>
                <div>
                  <InputLabel>Proposito</InputLabel>
                  <Select
                    className="width100"
                    value={projectPhase.purpose}
                    onChange={(e) => this.handleChange("purpose", e)}
                    inputProps={{
                      name: "purpose",
                      id: "age-simple",
                    }}
                  >
                    <MenuItem value={"Venta"}>Venta</MenuItem>
                    <MenuItem value={"Arrendar"}>Arrendar</MenuItem>
                  </Select>
                </div>
                <div>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    m={1}
                    p={1}
                    bgcolor="background.paper"
                  >
                    <Button
                      className="btn-edit"
                      size="small"
                      variant="contained"
                      onClick={() => this.props.save(this.state.projectPhase)}
                    >
                      Editar
                    </Button>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} className="background-table width100">
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <Typography variant="subtitle2" gutterBottom>
                    {`tipo de fase : ${this.props.phase.name}`}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                  <Button
                    onClick={() => this.setState({ openModalCreateType: true })}
                    variant="contained"
                    color="primary"
                  >
                    Crear
                  </Button>
                  <FormCreateType
                    openModalCreateType={this.state.openModalCreateType}
                    closeModalCreateType={() =>
                      this.setState({ openModalCreateType: false })
                    }
                    projectPhaseId={this.props.phase.id}
                    load={this.load}
                  />
                </Box>
                <div className="padding-left-right-25">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Id referencia</TableCell>
                        <TableCell align="left">Nombre</TableCell>
                        <TableCell align="left">Uso</TableCell>
                        <TableCell align="left">Area por tipo</TableCell>
                        <TableCell align="left">Precio en miles</TableCell>
                        <TableCell align="left">Precio en m2</TableCell>
                        <TableCell align="left">Estado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="without-padding">
                      {projectTypes.map((projectType) => (
                        <TableRow key={projectType.id}>
                          <TableCell align="left">
                            {projectType.ref_type_id}
                          </TableCell>
                          <TableCell align="left">{projectType.name}</TableCell>
                          <TableCell align="left">{projectType.use}</TableCell>
                          <TableCell align="left">
                            {projectType.area_by_type}
                          </TableCell>
                          <TableCell align="left">
                            {projectType.last_price_in_thousands}
                          </TableCell>
                          <TableCell align="left">
                            {projectType.last_price_in_m2}
                          </TableCell>
                          <TableCell align="left">
                            {projectType.status}
                          </TableCell>
                          <TableCell align="left" className="padding-10">
                            <Button
                              className="btn-edit"
                              size="small"
                              variant="contained"
                              onClick={() =>
                                this.setState({
                                  openModalEditType: true,
                                  currentProjectTypeId: projectType.id,
                                })
                              }
                            >
                              <EditIcon />
                            </Button>
                          </TableCell>
                          <TableCell align="left" className="padding-10">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() =>
                                this.setState({
                                  openModalCreateFeature: true,
                                  currentProjectTypeId: projectType.id,
                                })
                              }
                            >
                              <FeaturedPlayListIcon />
                            </Button>
                          </TableCell>
                          <TableCell align="left" className="padding-10">
                            <Button
                              color="secondary"
                              size="small"
                              variant="contained"
                              onClick={() => this.deleteType(projectType.id)}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
            <FormCreateFeature
              openModalCreateFeature={this.state.openModalCreateFeature}
              closeModalCreateFeature={() =>
                this.setState({ openModalCreateFeature: false })
              }
              projectTypeId={this.state.currentProjectTypeId}
            />
            <FormEditType
              openModalEditType={this.state.openModalEditType}
              closeModalEditType={() =>
                this.setState({ openModalEditType: false })
              }
              projectTypeId={this.state.currentProjectTypeId}
              projectPhaseId={this.state.projectPhase.id}
              load={() => this.setState({ load: true })}
              className="leonel"
              fecthProjectTypes={() => this.fecthProjectTypes()}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Divider />
        <br></br>
      </div>
    );
  }
}
