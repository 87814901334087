import React, { Component } from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  BooleanInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { affiliate_status } from "./";
const choices = [
  { id: true, description: "Activo" },
  { id: false, description: "Inactivo" },
];

export default class UsersCreate extends Component {
  render() {
    return (
      <Create title="Crear usuario" {...this.props}>
        <SimpleForm>
          <Grid container spacing={3} fullWidth>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="first_name" label="Nombres" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="last_name" label="Apellidos" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="email" label="Correo" type="email" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                fullWidth
                source="password"
                label="Contraseña"
                type="password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <ReferenceInput label="Empresas" source="company_id" reference="companies">
                                <SelectInput optionText="name" />
                            </ReferenceInput><br></br>
                            <ReferenceInput label="Rol de empresa" source="company_role_id" reference="company-role-options">
                                <SelectInput optionText="name" />
                            </ReferenceInput><br></br> */}

              <TextInput fullWidth source="phone_number" label="Teléfono" />
              <br></br>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                fullWidth
                label="Miembro"
                source="affiliate"
                choices={affiliate_status}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectInput
                fullWidth
                source="role"
                choices={choices}
                optionText="description"
                optionValue="id"
                label="Estado"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BooleanInput fullWidth label="Miembro" source="affiliate" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    );
  }
}
