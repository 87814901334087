import React, { Component } from 'react';
import { Create, SimpleForm, ReferenceInput, AutocompleteInput, NumberInput, SelectInput } from 'react-admin';
import { Grid } from '@material-ui/core';

export default class ConstructionCompaniesRankingCreate extends Component {

    render() {

        const months = [
            { num: 1, description: 'Enero' },
            { num: 2, description: 'Febrero' },
            { num: 3, description: 'Marzo' },
            { num: 4, description: 'Abril' },
            { num: 5, description: 'Mayo' },
            { num: 6, description: 'Junio' },
            { num: 7, description: 'Julio' },
            { num: 8, description: 'Agosto' },
            { num: 9, description: 'Septiembre' },
            { num: 10, description: 'Octubre' },
            { num: 11, description: 'Noviembre' },
            { num: 12, description: 'Diciembre' },
        ];

        const type_constructions = [
            { type_constructions: 'Vis', description: 'Vis' },
            { type_constructions: 'No vis', description: 'No vis' },
        ];

        const type = [
            { type: 'Unidades vendidas', description: 'Unidades vendidas' },
            { type: 'Ventas en menor numero de meses', description: 'Ventas en menor numero de meses' }
        ]

        return (
            <Create title={'Crear Blog'} {...this.props}>
                <SimpleForm>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <ReferenceInput
                                label="Empresa"
                                source="construction_company_id"
                                reference="construction-companies"
                                filterToQuery={searchText => ({ name: searchText })}>
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <NumberInput source="position" step={1} label="Posición" /><br></br>
                            <SelectInput source="month" choices={months} optionText="description" optionValue="num" />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput source="year" step={1} label="Año" /><br></br>
                            <SelectInput source="type_construction" label="Tipo de construcción" choices={type_constructions} optionText="description" optionValue="type_constructions" /><br></br>
                            <SelectInput source="type" label="Tipo de ranking" choices={type} optionText="description" optionValue="type" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        )
    }
}