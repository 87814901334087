import appFeathers from "../connections/feathers";

export const blogsService = appFeathers.service("blogs");
export const projectsService = appFeathers.service("projects");
export const locationsCitiesService = appFeathers.service("locations-cities");
export const zoneService = appFeathers.service("zones");
export const constructionCompaniesService = appFeathers.service(
  "construction-companies"
);
export const neighborhoodService = appFeathers.service("neighborhoods");
export const projectsPhasesService = appFeathers.service("projects-phases");
export const projectsTypesService = appFeathers.service("projects-types");
export const projectsSalesCompaniesService = appFeathers.service(
  "projects-sales-company"
);
export const zonesInterestService = appFeathers.service("zones-interest");
export const projectsZonesInteresService = appFeathers.service(
  "projects-zones-interest"
);
export const projectsTypesFeaturesService = appFeathers.service(
  "projects-types-features"
);
export const projectsImagesService = appFeathers.service("projects-images");
export const commonZonesService = appFeathers.service("common-zones");
export const projectsCommonZonesService = appFeathers.service(
  "projects-common-zones"
);

export const configurationsService = appFeathers.service('configurations');

// nueva
export const contactsService = appFeathers.service("contact-projects");


export const proccessProjectsCreatedBatchExcelService = appFeathers.service(
  "proccess-projects-created-batch-excel"
);

export const get_service = service => appFeathers.service(service);
