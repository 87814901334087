import React, { Component } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';

export default class CommonZonesCreate extends Component {
    render() {
        return (
            <Create title={"Crear Zona Común"} {...this.props}>
                <SimpleForm>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextInput source="name" label="Nombre" className="width100" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        );
    }
}
