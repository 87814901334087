import React, { Component } from "react";
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { UserTitle } from "./";
import { affiliate_status } from "./";
export default class UsersEdit extends Component {
  render() {
    return (
      <Edit title={<UserTitle />} {...this.props}>
        <SimpleForm>
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Grid item xs={12} fullWidth>
                <DisabledInput label="Id" source="id" fullWidth />
              </Grid>
              <Grid item xs={12} fullWidth>
                <TextInput source="email" label="Correo" fullWidth />
              </Grid>
              <Grid item xs={12} fullWidth>
                <TextInput source="first_name" label="Nombres" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="last_name" label="Apellidos" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="phone_number" label="Teléfono" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  type="password"
                  source="password"
                  label="Contraseña"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <SelectInput
                  fullWidth
                  label="Miembro"
                  source="affiliate"
                  choices={affiliate_status}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="company_nit" label="Nit" fullWidth />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <h4>Datos de la compañia</h4>
              </Grid>
              <Grid item xs={12}>
                <DisabledInput
                  label="Nombre"
                  source="construction_company.name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DisabledInput
                  label="Teléfono movil"
                  source="construction_company.phone"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DisabledInput
                  label="Teléfono fijo"
                  source="construction_company.nit"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DisabledInput
                  label="Dirección"
                  source="construction_company.address"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}
