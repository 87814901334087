import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { zonesInterestService, projectsZonesInteresService } from '../../../services/feathers-rest';
import { Grid, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core';
import { DeleteIcon } from '../../icons';
import { message } from 'antd';

export default class ProjectZonesInterestSelect extends Component {
    state = {
        projectzonesInterest: [],
        project_id: null,
        load: true,
        zone_interest_id: null,
        journey_time: null,
        zonesInterest: [
            { value: '', label: '' }
        ]
    };

    async componentDidUpdate() {
        if (this.state.load) {
            try {
                const projectzonesInterest = await projectsZonesInteresService.find({ query: { project_id: this.props.id }, paginate: false }).then(it => it.data);
                const zonesInterest = await zonesInterestService.find({ query: { id: { $nin: projectzonesInterest.map(it => (it.zone_interest_id)) }, $limit: 10000 }, paginate: false }).then(it => it.data);
                this.setState({ load: false })
                this.setState({ zonesInterest: zonesInterest.map((it) => ({ value: it.id, label: it.name })), project_id: this.props.id, projectzonesInterest });
            } catch (err) {
                message.error(err.message);
            }
        }
    }

    handleChange = (name, e) => {
        this.setState({ [name]: e.target.value })
    }

    saveZoneInterest = async () => {
        await projectsZonesInteresService.create(this.state);
        this.setState({ load: true, journey_time: '', zone_interest_id: null });
        this.componentDidUpdate();
    }

    deleteZoneInterest = async (id) => {
        await projectsZonesInteresService.remove(id)
        this.setState({ load: true })
        this.componentDidUpdate();
    }

    render() {
        const { zonesInterest, projectzonesInterest } = this.state;
        return (
            <Fragment>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue=""
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={zonesInterest}
                                onChange={e => {
                                    if (!e) return;
                                    this.setState({ zone_interest_id: e.value })
                                }}
                            />
                        </div>
                        <div>
                            <TextField
                                id="standard-name"
                                className="width100"
                                label="Tiempo"
                                value={this.state.journey_time}
                                onChange={(e) => this.handleChange('journey_time', e)}
                                margin="normal"
                            />
                        </div>
                        <br></br>
                        <div>
                            <Button
                                onClick={() => this.saveZoneInterest()}
                                color="primary"
                                variant="contained"
                            >
                                guardar
                        </Button>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Zona</TableCell>
                                    <TableCell align="right">Tiempo</TableCell>
                                    <TableCell align="right">Eliminar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projectzonesInterest.map(zone => (
                                    <TableRow key={zone.id}>
                                        <TableCell component="th" scope="row">
                                            {zone.zone_interest.name}
                                        </TableCell>
                                        <TableCell align="right">{zone.journey_time}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => this.deleteZoneInterest(zone.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}