import React, { Component, useRef, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Link,
  EditButton,
  Filter,
  TextInput,
  CardActions,
  ExportButton,
  SelectInput,
  CreateButton,
  ReferenceInput,
} from "react-admin";
import rowStyle from "./components/rowStyle";
import { Button } from "@material-ui/core";
import axios from "axios";
import { host } from "../../connections/feathers";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { proccessProjectsCreatedBatchExcelService } from "../../services/feathers-rest";
import CheckIcon from "@material-ui/icons/Check";

const ProjectsActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  sendFile,
  loading,
  loadingShow,
}) => {
  const inputFile = useRef(null);

  const [loadingT, setLoading] = useState(false);
  const [loadingSyncUp, setLoadingSyncUp] = useState(false);

  const syncUp = async () => {
    setLoadingSyncUp(true);
    await proccessProjectsCreatedBatchExcelService
      .create({})
      .then((it) => setLoadingSyncUp(false))
      .catch(() => setLoadingSyncUp(false));
  };

  const sendFileT = async (file) => {
    setLoading(true);
    try {
      const data = new FormData();
      const token = localStorage.getItem("feathers-jwt");

      data.append("excel_file", file);
      const a = await axios
        .post(`${host}/batch-projects-excel`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  };

  const onButtonFile = () => {
    inputFile.current.click();
  };

  return (
    <CardActions>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <input
        accept="/*"
        // className={classes.input}
        style={{ display: "none" }}
        // id="button-file"
        ref={inputFile}
        multiple
        type="file"
        onChange={(e) => sendFileT(e.target.files[0])}
      />
      <label htmlFor="button-file">
        <Button
          color="primary"
          className="paddingHeight"
          onClick={onButtonFile}
        >
          {loadingT && (
            <IconButton aria-label="delete" size="small">
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
          Cargar Excel
        </Button>
      </label>
      <Button color="primary" className="paddingHeight" onClick={syncUp}>
        {loadingSyncUp && (
          <IconButton aria-label="delete" size="small">
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          </IconButton>
        )}
        Sincronizar
      </Button>
    </CardActions>
  );
};

const statuses = [
  { value: "Activo", description: "Activo" },
  { value: "Inactivo", description: "Inactivo" },
];

const ProjectFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre del proyecto" source="name" />
    <TextInput label="Nombre de constructora" source="construction_name" />
    <ReferenceInput
      label="Ciudad"
      source="city_id"
      reference="locations-cities"
    >
      <SelectInput source="name" />
    </ReferenceInput>
    <SelectInput
      choices={statuses}
      source="status"
      label="Estado"
      optionText="description"
      optionValue="value"
    />
  </Filter>
);

const ConstructionCompaniesField = ({ record, ...rest }) => {
  return record && record.construction_company ? (
    <Link to={`/construction-companies/${record.construction_company_id}`}>
      <TextField
        source="construction_company.name"
        record={record}
        {...rest}
        style={{ color: "#3f51b5" }}
      />
    </Link>
  ) : (
    "Sin Asignar"
  );
};

const AdministarionField = ({ record }) => {
  return record.edited_by == "No editado"
    ? "Coordenada Urbana"
    : record.edited_by == "Administrador"
    ? "Administador"
    : "Constructora";
};

const EditedByField = ({ record }) => {
  return record.created_from == "lote excel" ? "Coordenada Urbana" : "Unitario";
};

const ProjectsSalesCompany = ({ record }) => {
  return record.selling_company
    ? `${record.selling_company.name}`
    : "No asignada";
};
export default class ProjectList extends Component {
  state = {
    loading: false,
    // loadingShow: false,
  };

  loading = (loading) => {
    this.setState({ loading: loading });
  };

  sendFile = async (file) => {
    // console.log(this.loading);
    try {
      const data = new FormData();
      const token = localStorage.getItem("feathers-jwt");

      data.append("excel_file", file);
      const a = await axios.post(`${host}/batch-projects-excel`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });
      this.setState({
        loading: false,
        ShowSnackBar: true,
        message: "Archivo cargado",
        variant: "success",
      });
    } catch (error) {
      this.setState({
        loading: false,
        ShowSnackBar: true,
        message: "Archivo no cargado",
        variant: "error",
      });
    }
  };

  render() {
    return (
      <List
        {...this.props}
        title="Listado de proyectos"
        filters={<ProjectFilter />}
        actions={
          <ProjectsActions
            sendFile={this.sendFile}
            loading={this.loading}
            loadingShow={this.state.loading}
          />
        }
      >
        <Datagrid rowStyle={rowStyle}>
          <TextField source="id" label="Id" />
          <TextField source="name" label="Nombre" />
          <ReferenceField
            label="Ciudad"
            source="city_id"
            reference="locations-cities"
          >
            <TextField source="name" />
          </ReferenceField>
          <ConstructionCompaniesField label="Constructora" />
          <ProjectsSalesCompany label="Vendedora" />
          <EditedByField label="Creado por" />
          {/* <TextField source="edited_by" label="Editado por" /> */}
          <TextField source="status" label="Estado" />
          <TextField source="priority" label="Destacado" />
          <AdministarionField label="Sincronizado por" />
          <EditButton label="" /* className="btn-edit" */ />
        </Datagrid>
      </List>
    );
  }
}
