import React, { Component } from "react";
import { projectsSalesCompaniesService } from "../../../services/feathers-rest";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardMedia,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import SnackBar from "./snackbars";
import { S3File } from "../../s3";
export default class ProjectsTypes extends Component {
  state = {
    load: true,
    projectSalesCompany: {
      name: "",
      email: "",
      whatsapp: "",
      id: "",
      phone: "",
      address: "",
      name: "",
      nit: "",
      ShowSnackBar: false,
    },
  };

  async componentDidMount() {
    this.fetchSalesCompany();
  }

  fetchSalesCompany = async () => {
    const projectSalesCompany = await projectsSalesCompaniesService
      .find({ query: { project_id: this.props.id }, paginate: false })
      .then((it) => it.data[0]);
    projectSalesCompany
      ? this.setState({ projectSalesCompany, load: false })
      : this.setState({ load: false });
  };

  handleChange = (name, e) => {
    this.setState({
      projectSalesCompany: Object.assign({}, this.state.projectSalesCompany, {
        [name]: e.target.value,
      }),
    });
  };

  saveProjectSalesCompany = async () => {
    projectsSalesCompaniesService
      .patch(this.state.projectSalesCompany.id, this.state.projectSalesCompany)
      .then(() => {
        this.setState({ ShowSnackBar: true });
        setTimeout(() => {
          this.setState({ ShowSnackBar: false });
        }, 3000);
      });
  };

  handleUploadFinish = async (path) => {
    await projectsSalesCompaniesService.patch(
      this.state.projectSalesCompany.id,
      { path_logo: path }
    );
    this.fetchSalesCompany();
  };

  render() {
    const { projectSalesCompany, ShowSnackBar } = this.state;
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <div>
              <TextField
                id="standard-name"
                label="Razón social"
                className="width100"
                value={projectSalesCompany.name}
                onChange={(e) => this.handleChange("name", e)}
                margin="normal"
              />
            </div>
            <div>
              <TextField
                id="standard-nit"
                label="Nit"
                className="width100"
                value={projectSalesCompany.nit}
                onChange={(e) => this.handleChange("nit", e)}
                margin="normal"
              />
            </div>
            <div>
              <TextField
                id="email"
                label="Email"
                className="width100"
                value={projectSalesCompany.email}
                onChange={(e) => this.handleChange("email", e)}
                margin="normal"
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="standard-address"
                label="Dirección"
                className="width100"
                value={projectSalesCompany.address}
                onChange={(e) => this.handleChange("address", e)}
                margin="normal"
              />
            </div>
            <div>
              <TextField
                id="standard-phone"
                label="Telefono"
                className="width100"
                value={projectSalesCompany.phone}
                onChange={(e) => this.handleChange("phone", e)}
                margin="normal"
              />
            </div>

            <div>
              <TextField
                id="whatsapp"
                label="Whatsapp"
                className="width100"
                value={projectSalesCompany.whatsapp}
                onChange={(e) => this.handleChange("whatsapp", e)}
                margin="normal"
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <Card className="box-shadow-0">
              <CardHeader title="imagen principal" />
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                image={`https://construcaribe.s3.amazonaws.com/${projectSalesCompany.path_logo}`}
                title="Contemplative Reptile"
              />
              <CardActions>
                <S3File
                  handleUploadFinish={this.handleUploadFinish}
                  idComponent="main"
                  path={"projects-images"}
                  idComponent="logo-projets-sales-company"
                />
              </CardActions>
            </Card>
          </Grid>
          <div>
            <Button
              variant="contained"
              color="primary"
              className="width100"
              onClick={this.saveProjectSalesCompany}
            >
              Guardar
            </Button>
          </div>
          <div>
            <SnackBar
              ShowSnackBar={ShowSnackBar}
              message="Datos basicos de la empresa vendedora actualizados "
            />
          </div>
        </Grid>
      </div>
    );
  }
}
