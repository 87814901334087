import React, { Component } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import Grid from '@material-ui/core/Grid'

const Title = ({ record }) => record ? `Editando el registro: ${record.id}` : ''

export default class ConfigurationsEdit extends Component {
  render () {
    return (<Edit title={<Title />} {...this.props}>
      <SimpleForm>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <TextInput label="Clave" source="key" fullWidth />
           </Grid>
          <Grid item xs={6}>
            <TextInput label="Valor" source="value" fullWidth />
           </Grid>
          <Grid item xs={12}>
            <TextInput label="Descripción" source="description" fullWidth />
           </Grid>
        </Grid>
      </SimpleForm>
    </Edit>)
  }
}