import React, {Component} from 'react';
import {Button, TextField, Dialog, DialogActions, 
  DialogContent, Grid, MenuItem, DialogTitle, InputLabel
} from '@material-ui/core';
import SnackBar from './snackbars';
import {projectsTypesFeaturesService} from '../../../services/feathers-rest';

export default class FormCreateFeature extends Component{

  state={
    load:true,
    new:null,
    message:null,
    projectTypesFeautures:{
        project_type_id:null,
        ref_project_type_id:null,
        bedrooms: 0,
        bathrooms:0,
        balcony_terrace:0,
        study_rooms:0,
        warehouse:0,
        additional_office:0,
        mezzanine:0,
        service_room_and_bathroom:0,
        dining_room:0,
        fireplace:0,
        kitchen:0,
        clothing_area:0,
        backyard:0,
        attic:0,
        bathroom_expandable:0,
        bedrooms_expandable:0
    },
    projectTypesFeautures1:{
        project_type_id:null,
        ref_project_type_id:null,
        bedrooms: 0,
        bathrooms:0,
        balcony_terrace:0,
        study_rooms:0,
        warehouse:0,
        additional_office:0,
        mezzanine:0,
        service_room_and_bathroom:0,
        dining_room:0,
        fireplace:0,
        kitchen:0,
        clothing_area:0,
        backyard:0,
        attic:0,
        bathroom_expandable:0,
        bedrooms_expandable:0
    },
    ShowSnackBar:false
  }

  handleChange =(name, e) => {
    this.setState({projectTypesFeautures:{...this.state.projectTypesFeautures, [name]:e.target.value}})
  }

  async componentDidUpdate(prevProps, prevState){
      if(prevProps.projectTypeId != this.props.projectTypeId){
        const projectTypesFeautures = await projectsTypesFeaturesService.find({query:{project_type_id:this.props.projectTypeId}, paginate:false}).then(it => it.data[0])
        if(projectTypesFeautures)
            this.setState({projectTypesFeautures, load:false, new:false, projectTypesFeautures:{...projectTypesFeautures, project_type_id:this.props.projectTypeId} })
        else
            this.setState({load:false, new:true, projectTypesFeautures:{...this.state.projectTypesFeautures1 ,project_type_id:this.props.projectTypeId}})
      }
  }

  closeSnackBar(){
    setTimeout(()=>{
        this.setState({ShowSnackBar:false})
    }, 3000)
  }

  saveProyectsTypesFeatures = async () =>{
    if(this.state.new){
  
        if(this.state.projectTypesFeautures.ref_project_type_id == null){
            this.setState({ShowSnackBar: true, message:'La referencia del proyecto no puede estar vacia'})
            this.props.closeModalCreateFeature()
            this.closeSnackBar()
        } else{
            projectsTypesFeaturesService.create(this.state.projectTypesFeautures).then(()=>(
                this.setState({ShowSnackBar: true, message:'Caracteristicas creadas'}),
                this.props.closeModalCreateFeature(),
                this.closeSnackBar()
            )).catch((e)=>{
                this.setState({ShowSnackBar: true, message:e.message})
                this.props.closeModalCreateFeature()
                this.closeSnackBar()
            })
        }
    }else{
        projectsTypesFeaturesService.patch(this.state.projectTypesFeautures.id,this.state.projectTypesFeautures)
        .then(
            this.setState({ShowSnackBar: true, message:'Caracteristicas modificadas'}),
            this.props.closeModalCreateFeature(),
            this.closeSnackBar())
        .catch((e)=>{
            this.setState({ShowSnackBar: true, message:e.message})
            this.props.closeModalCreateFeature()
            this.closeSnackBar()
        })
    }
  }

  render(){
    const {openModalCreateFeature} = this.props;
    const { projectTypesFeautures, ShowSnackBar, message } = this.state;
    return(
      <div>
        <Dialog open={openModalCreateFeature} onClose={this.props.closeModalCreateFeature} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Crear caracteristica</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    value={(projectTypesFeautures.ref_project_type_id == null) ? 'Sin referencia' : projectTypesFeautures.ref_project_type_id }
                    autoFocus
                    margin="dense"
                    label="Referencia"
                    type="text"
                    onChange={(e) => this.handleChange('ref_project_type_id', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.bedrooms}
                    autoFocus
                    margin="dense"
                    label="Alcobas"
                    type="number"
                    onChange={(e) => this.handleChange('bedrooms', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.bathrooms}
                    autoFocus
                    margin="dense"
                    label="Baños"
                    type="number"
                    onChange={(e) => this.handleChange('bathrooms', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.balcony_terrace}
                    autoFocus
                    margin="dense"
                    label="Balcón terraza"
                    type="number"
                    onChange={(e) => this.handleChange('balcony_terrace', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.kitchen}
                    autoFocus
                    margin="dense"
                    label="Cocina"
                    type="number"
                    onChange={(e) => this.handleChange('kitchen', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.bedrooms_expandable}
                    autoFocus
                    margin="dense"
                    label="Cuarto expandible"
                    onChange={(e) => this.handleChange('bedrooms_expandable', e)}
                    type="number"
                    fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={projectTypesFeautures.study_rooms}
                    autoFocus
                    margin="dense"
                    label="Estudio"
                    type="number"
                    onChange={(e) => this.handleChange('study_rooms', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.warehouse}
                    autoFocus
                    margin="dense"
                    label="Almacén"
                    type="number"
                    onChange={(e) => this.handleChange('warehouse', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.additional_office}
                    autoFocus
                    margin="dense"
                    label="Oficina adicional"
                    onChange={(e) => this.handleChange('additional_office', e)}
                    type="number"
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.mezzanine}
                    autoFocus
                    margin="dense"
                    label="Mezzanine"
                    onChange={(e) => this.handleChange('mezzanine', e)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                <TextField
                    value={projectTypesFeautures.service_room_and_bathroom}
                    autoFocus
                    margin="dense"
                    label="Cuarto de servicio"
                    type="number"
                    onChange={(e) => this.handleChange('service_room_and_bathroom', e)}
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.dining_room}
                    autoFocus
                    margin="dense"
                    id="unit_by_type"
                    label="Sala/Comedor"
                    onChange={(e) => this.handleChange('dining_room', e)}
                    type="number"
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.vestier}
                    autoFocus
                    margin="dense"
                    id="vestier"
                    label="Baño Social"
                    onChange={(e) => this.handleChange('vestier', e)}
                    type="number"
                    fullWidth
                  />
                  <TextField
                    value={projectTypesFeautures.fireplace}
                    autoFocus
                    margin="dense"
                    label="Chimenea"
                    onChange={(e) => this.handleChange('fireplace', e)}
                    type="number"
                    fullWidth
                  />
                  <TextField
                        value={projectTypesFeautures.kitchen}
                        autoFocus
                        margin="dense"
                        label="Cocina"
                        onChange={(e) => this.handleChange('kitchen', e)}
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        value={projectTypesFeautures.clothing_area}
                        autoFocus
                        margin="dense"
                        label="Área de ropa"
                        onChange={(e) => this.handleChange('clothing_area', e)}
                        type="number"
                        fullWidth
                    />
                    <TextField
                        value={projectTypesFeautures.backyard}
                        autoFocus
                        margin="dense"
                        label="Patio interior"
                        onChange={(e) => this.handleChange('backyard', e)}
                        type="number"
                        fullWidth
                    />
                    <TextField
                        value={projectTypesFeautures.attic}
                        autoFocus
                        margin="dense"
                        label="Ático"
                        onChange={(e) => this.handleChange('attic', e)}
                        type="number"
                        fullWidth
                    />
                    <TextField
                        value={projectTypesFeautures.bathroom_expandable}
                        autoFocus
                        margin="dense"
                        label="Baño expandible"
                        onChange={(e) => this.handleChange('bathroom_expandable', e)}
                        type="number"
                        fullWidth
                    />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.props.closeModalCreateFeature} color="primary">
                Cerrar
            </Button>
            <Button onClick={() => this.saveProyectsTypesFeatures()} color="primary">
                Guardar
            </Button>
            </DialogActions>
        </Dialog>
        <SnackBar ShowSnackBar={ShowSnackBar} message={message}/>
      </div>
    );
  }
}