import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ArtTrack,
  ViewDay,
  LocationCity,
  LocalOffer,
  Accessibility,
  Person,
  ArrowUpward,
  Style,
  ImportContacts,
  Settings,
  Business,
} from "@material-ui/icons";

import { MenuItemLink, Responsive, DashboardMenuItem } from "react-admin";

export default class Menu extends Component {
  state = {
    menuCatalog: false,
    menuSales: false,
    menuCustomers: false,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  };

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, logout } = this.props;
    return (
      <div>
        {/* <DashboardMenuItem onClick={onMenuClick} /> */}
        <MenuItemLink
          className={"menuItems"}
          to={`/projects`}
          primaryText={"Proyectos"}
          onClick={onMenuClick}
          leftIcon={<ArtTrack />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/blogs`}
          primaryText={"Blogs"}
          onClick={onMenuClick}
          leftIcon={<ViewDay />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/neighborhoods`}
          primaryText={"Barrios"}
          onClick={onMenuClick}
          leftIcon={<LocationCity />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/zones-interest`}
          primaryText={"Zonas de interés"}
          onClick={onMenuClick}
          leftIcon={<Accessibility />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/common-zones`}
          primaryText={"Zonas comunes"}
          onClick={onMenuClick}
          leftIcon={<LocalOffer />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/users`}
          primaryText={"Usuarios"}
          onClick={onMenuClick}
          leftIcon={<Person />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/construction-companies-ranking`}
          primaryText={"Ranking constructoras"}
          onClick={onMenuClick}
          leftIcon={<ArrowUpward />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/construction-companies`}
          primaryText={"Constructoras"}
          onClick={onMenuClick}
          leftIcon={<Business />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/design`}
          primaryText={"Diseño"}
          onClick={onMenuClick}
          leftIcon={<Style />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/magazine`}
          primaryText={"Revista"}
          onClick={onMenuClick}
          leftIcon={<ImportContacts />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/contact-projects`}
          primaryText={"Contactos"}
          onClick={onMenuClick}
          leftIcon={<Person />}
        />
        <MenuItemLink
          className={"menuItems"}
          to={`/configurations`}
          primaryText={"Variables del sistema"}
          onClick={onMenuClick}
          leftIcon={<Settings />}
        />
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
      </div>
    );
  }
}
