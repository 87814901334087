import React from "react";
import "antd/dist/antd.css";
import { Admin, Resource } from "react-admin";
import { restClient, authClient } from "ra-data-feathers";
import feathers from "./connections/feathers";
import { Layout } from "./components/layout";
import { UsersList, UsersEdit, UsersCreate } from "./components/users";
import { BlogsList, BlogsCreate, BLogEdit } from "./components/blogs";
import { DesignList, DesignCreate, DesignEdit } from "./components/design";
import { ProjectList, ProjectEdit, ProjectCreate } from "./components/projects";
import { EditProjectPhase } from "./components/projects-phases";
import { ProjectTypesShow } from "./components/projects-types";
import {
  ZonesInterestCreate,
  ZonesInterestList,
  ZonesInterestEdit,
} from "./components/zones-interest";
import {
  NeighborhoodsList,
  NeighborhoodsCreate,
  NeighborhoodsEdit,
} from "./components/neighborhoods";
import {
  CommonZonesCreate,
  CommonZonestList,
} from "./components/common-zones";
import {
  ConstructionCompaniesRankingList,
  ConstructionCompaniesRankingCreate,
  ConstructionCompaniesRankingEdit,
} from "./components/construction-companies-ranking";
import CommonZonesEdit from "./components/common-zones/edit";
import {
  MagazineCreate,
  MagazineEdit,
  MagazineList,
} from "./components/magazine";
import { ContactList } from "./components/contact-projects";
import { ConfigurationsList, ConfigurationsCreate, ConfigurationsEdit } from './components/configurations'
import { ConstructionCompanieCreate, ConstructionCompanieEdit, ConstructionCompaniesList } from "./components/construction-companies";

const authClientOptions = {
  storageKey: "feathers-jwt",
  authenticate: { strategy: "local" },
  permissionsKey: "permissions",
  permissionsField: "roles",
  redirectTo: "/login",
};


const restClientOptions = {
  id: "id", // In this example, the database uses '_id' rather than 'id'
  usePatch: true, // Use PATCH instead of PUT for updates
};

const App = () => [
  <Admin
    authProvider={authClient(feathers, authClientOptions)}
    dataProvider={restClient(feathers, restClientOptions)}
    appLayout={Layout}
  // dashboard={dashboard}
  >
    <Resource
      name="projects"
      list={ProjectList}
      edit={ProjectEdit}
      create={ProjectCreate}
    />
    <Resource
      name="configurations"
      list={ConfigurationsList}
      edit={ConfigurationsEdit}
      create={ConfigurationsCreate}
    />
    <Resource
      name="blogs"
      list={BlogsList}
      create={BlogsCreate}
      edit={BLogEdit}
    />
    <Resource
      name="design"
      create={DesignCreate}
      list={DesignList}
      edit={DesignEdit}
    />
    <Resource name="locations-cities" />
    <Resource name="zones" />
    {/* <Resource name="construction-companies" /> */}
    <Resource name="projects-phases" edit={EditProjectPhase} />
    <Resource name="projects-types" show={ProjectTypesShow} />
    <Resource
      name="neighborhoods"
      list={NeighborhoodsList}
      create={NeighborhoodsCreate}
      edit={NeighborhoodsEdit}
    />
    <Resource
      name="zones-interest"
      create={ZonesInterestCreate}
      list={ZonesInterestList}
      edit={ZonesInterestEdit}
    />
    <Resource
      name="common-zones"
      create={CommonZonesCreate}
      edit={CommonZonesEdit}
      list={CommonZonestList}
    />
    <Resource
      name="contact-projects"
      list={ContactList}
    />
    <Resource
      name="users"
      create={UsersCreate}
      edit={UsersEdit}
      list={UsersList}
    />
    <Resource
      name="construction-companies-ranking"
      list={ConstructionCompaniesRankingList}
      create={ConstructionCompaniesRankingCreate}
      edit={ConstructionCompaniesRankingEdit}
    />
    <Resource
      name="construction-companies"
      list={ConstructionCompaniesList}
      create={ConstructionCompanieCreate}
      edit={ConstructionCompanieEdit}
    />
    <Resource
      name="magazine"
      list={MagazineList}
      edit={MagazineEdit}
      create={MagazineCreate}
    />
  </Admin>,
];

export default App;
//
