import React, { Component } from "react";
import RichTextInput from "ra-input-rich-text";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { S3File } from "../s3";
import { Grid } from "@material-ui/core";
import { blogsService } from "../../services";

const ImageField = ({ record }) => {
  return (
    <img
      src={`https://construcaribe.s3.amazonaws.com/${record.cover_image}`}
      alt="Cover"
      height="20%"
      width="20%"
    />
  );
};

var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  ["link", "image"],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  [{ theme: "bubble" }]["clean"] // remove formatting button
];

class BlogCreate extends Component {
  saveCoverBlog = async urls => {
    const { match } = this.props;
    await blogsService
      .patch(match.params.id, {
        cover_image: `${urls}`
      })
      .then();
  };

  render() {
    return (
      <Edit title={"Crear Blog"} {...this.props}>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                source="title"
                value="adfsads"
                label="Titulo"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 50 }}>
              <S3File
                handleUploadFinish={this.saveCoverBlog}
                service="blog"
                id={this.props.id}
                idComponent="main"
                path={"icons-common-zones"}
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 50 }}>
              <TextInput source="title_slug" label="Slug" fullWidth />
            </Grid>
          </Grid>
          <ImageField />
          <RichTextInput
            source="content"
            validation={{ required: true }}
            toolbar={toolbarOptions}
            label="Contenido"
          />
        </SimpleForm>
      </Edit>
    );
  }
}

export default BlogCreate;
