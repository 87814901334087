import React, {Component} from 'react';
import {Button, TextField, Dialog, DialogActions, 
  DialogContent, Select, Grid, DialogTitle, MenuItem
} from '@material-ui/core';
import SnackBar from './snackbars';

import {projectsPhasesService} from '../../../services/feathers-rest';

export default class ModalCreatePhase extends Component{
  state={
    project_id:null,
    name:null,
    status:'ABIERTA',
    ref_id_phase:'',
    number_of_units:null,
    purpose:'Venta',
    use:'Casa',
    ref_code_phase:'',
    showSnackBar:false
  }

  handleChange(name, e){
    this.setState({[name]: e.target.value});
  }

  handleClose = () =>{
    this.props.onClosed()
  }

  updatePases= () =>{
    this.props.componentDidUpdate()
  }

  saveProjectPhase(){
    this.setState({ref_code_phase:this.state.ref_id_phase})
    projectsPhasesService.create(this.state)
    .then(result => {
      this.setState({showSnackBar:true})
      setTimeout(()=>{
        this.setState({showSnackBar:false})
        this.handleClose()
        this.props.load()
        this.props.componentDidUpdate()
      },3000)
    })
    .catch((error) => console.log(error))
  }

  componentDidUpdate(){
    if(!this.state.project_id){
      this.setState({project_id:this.props.idProject})
    }
  }

  render(){
    const {openModalCreatePhase} = this.props
    return(
      <div>
        <Dialog open={openModalCreatePhase} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <form autoComplete="off">
            <DialogTitle id="form-dialog-title">Crear nueva fase</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    <TextField
                      autoFocus
                      value={this.state.name}
                      margin="dense"
                      id="name"
                      label="Nombre"
                      type="text"
                      fullWidth
                      required
                      onChange={(e)=> this.handleChange('name', e)}
                    />
                  </div>
                  <br></br>
                  <div>
                    <Select
                      value={this.state.status}
                      autoWidth={true}
                      labelWidth={5}
                      onChange={(e)=> this.handleChange('status', e)}
                    >
                      <MenuItem value={'ABIERTA'}>Abierta</MenuItem>
                      <MenuItem value={'CERRADA'}>Cerrada</MenuItem>
                    </Select>
                  </div>
                  <div>
                    <TextField
                      autoFocus
                      value={this.state.ref_id_phase}
                      margin="dense"
                      id="ref_id_phase"
                      label="Referencia"
                      type="number"
                      fullWidth
                      onChange={(e)=> this.handleChange('ref_id_phase', e)}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="number_of_units"
                      label="Numero de unidades"
                      type="number"
                      fullWidth
                      onChange={(e)=> this.handleChange('number_of_units', e)}
                    />
                  </div>
                  <br></br>
                  <div>
                    <Select
                      value={this.state.purpose}
                      autoWidth={true}
                      labelWidth={5}
                      onChange={(e)=> this.handleChange('purpose', e)}
                    >
                      <MenuItem value={'Venta'}>Venta</MenuItem>
                      <MenuItem value={'Arrendar'}>Arrendar</MenuItem>
                    </Select>
                  </div>
                  <br></br>
                  <div>
                    <Select
                      value={this.state.use}
                      autoWidth={true}
                      labelWidth={5}
                      onChange={(e)=> this.handleChange('use', e)}
                    >
                      <MenuItem value={'Casa'}>Casa</MenuItem>
                      <MenuItem value={'Apartamento'}>Apartamento</MenuItem>
                      <MenuItem value={'Local'}>Local</MenuItem>
                      <MenuItem value={'Bodega'}>Bodega</MenuItem>
                      <MenuItem value={'Oficina'}>Oficina</MenuItem>
                      <MenuItem value={'Habitaciones'}>Habitaciones</MenuItem>
                    </Select>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.saveProjectPhase()} color="primary">
                Guardar
              </Button>
            </DialogActions >
          </form>
          <SnackBar ShowSnackBar={this.state.showSnackBar} message="Fase creada" />
        </Dialog>
      </div>
    )
  }
}