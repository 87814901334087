import React, { Component } from 'react';
import { Create, SimpleForm, NumberInput, SelectInput, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';

export default class ConstructionCompanieCreate extends Component {

    render() {

        const status = [
            { value: "Activa", description: "Activa" },
            { value: "Inactiva", description: "Inactiva" },
        ];

        return (
            <Create title={'Crear Compañía'} {...this.props}>
                <SimpleForm>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid>
                                <TextInput source="name" label="Nombre de Empresa" />
                            </Grid>
                            <Grid>
                                <TextInput source="phone" label="Teléfono" /><br></br>
                            </Grid>
                            <Grid>
                                <TextInput source="email" label="Email" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid>
                                <NumberInput source="nit" label="NIT" />
                            </Grid>
                            <Grid>
                                <SelectInput source="status" choices={status} optionText="description" optionValue="value" /><br></br>
                            </Grid>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        )
    }
}