import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput, LongTextInput, NumberInput } from 'react-admin';
import { Grid, Button } from '@material-ui/core';

// const optionsTypesLivingPlace = [
//     { value: 'No VIS', description: 'No VIS' },
//     { value: 'SIN ASIGNAR', description: 'SIN ASIGNAR' },
//     { value: 'VIS', description: 'VIS' },
// ];

const optionsStatus = [
    { value: 'Activo', description: 'Activo' },
    { value: 'Inactivo', description: 'Inactivo' },
];

const optionsGeneralUse = [
    { value: 'Residencial', description: 'Residencial' },
    { value: 'No residencial', description: 'No residencial ' },
];

const optionsStratus = [
    { value: 1, description: '1' },
    { value: 2, description: '2' },
    { value: 3, description: '3' },
    { value: 4, description: '4' },
    { value: 5, description: '5' },
    { value: 6, description: '6' },
    { value: 7, description: '7' },
];

export default class ProjectCreate extends Component {
    render() {
        return (
            <Create title={"Crear proyecto"} {...this.props}>
                <SimpleForm>
                    <Grid container spacing={4}>
                        <Grid item xs={4} xl={4}>
                            <TextInput source="name" label="Nombre del Proyecto" /* className="width100" */ />
                            <TextInput source="ref_project_id" label="Referencia" /* className="width100" */ />
                            <TextInput source="ref_project_code" label="Código" /* className="width100" */ />
                            <TextInput source="lat" label="Latitud" /* className="width100" */ />
                            <TextInput source="long" label="Longitud" /* className="width100" */ />
                            <TextInput source="phone" label="Telefono" /* className="width100" */ />
                            <TextInput source="address" label="Dirección" /* className="width100" */ />
                            <TextInput source="fiduciary_entity" label="Entidad fiduciaria" /* className="width100" */ />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                            <ReferenceInput label="Constructora" source="construction_company_id" reference="construction-companies" /* className="width100" */>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput label="Ciudad" source="city_id" reference="locations-cities" /* className="width100" */>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput label="Zona" source="zone_id" reference="zones" /* className="width100" */>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput label="Barrio" source="neighborhood_id" reference="neighborhoods" /* className="width100" */>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                            <SelectInput label="Uso general" source="general_use" choices={optionsGeneralUse} optionText="description" optionValue="value" /* className="width100" */ />
                            <SelectInput label="Estado" source="status" choices={optionsStatus} optionText="description" optionValue="value" /* className="width100" */ />
                            <NumberInput source="number_of_blocks" label="Numero de bloques" /* className="width100" */ />
                            <NumberInput source="number_of_units" label="Numero de unidades" /* className="width100" */ />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                            <SelectInput label="Estrato" source="stratus" choices={optionsStratus} optionText="description" optionValue="value" /* className="width100" */ />
                            {/* <SelectInput label="Tipo de vivienda" source="type_living_place" choices={optionsTypesLivingPlace} optionText="description" optionValue="value" className="width100" /> */}
                            <TextInput source="type_living_place" label="Tipo de vivienda" />
                            <LongTextInput source="project_description" label="Descripción del proyecto" />
                            <LongTextInput source="zones_interest_description" label="Descripción de las zonas de interés" />
                            <LongTextInput source="address_description" label="Descripción de la dirección" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        );
    }
}
