import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, LongTextInput  } from 'react-admin';

export default class NeighborhoodsCreate extends Component {
    render() {
        return (
            <Create title={"Crear barrio"} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" label="Nombre" className="width100"/>
                    <LongTextInput source="description" className="width100"/>
                </SimpleForm>
            </Create>
        );
    }
}
