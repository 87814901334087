import React, { Component } from 'react';
import {
    List, Datagrid, TextField, ReferenceField, NumberField,
    EditButton, Filter, SelectInput, NumberInput
} from 'react-admin';

const months = [
    { num: 1, description: 'Enero' },
    { num: 2, description: 'Febrero' },
    { num: 3, description: 'Marzo' },
    { num: 4, description: 'Abril' },
    { num: 5, description: 'Mayo' },
    { num: 6, description: 'Junio' },
    { num: 7, description: 'Julio' },
    { num: 8, description: 'Agosto' },
    { num: 9, description: 'Septiembre' },
    { num: 10, description: 'Octubre' },
    { num: 11, description: 'Noviembre' },
    { num: 12, description: 'Diciembre' },
];

const type_constructions = [
    { type_constructions: 'Vis', description: 'Vis' },
    { type_constructions: 'No vis', description: 'No vis' },
];

const type = [
    { type: 'Unidades vendidas', description: 'Unidades vendidas' },
    { type: 'Ventas en menor numero de meses', description: 'Ventas en menor numero de meses' }
]

const ConstructionCompaniesRankingFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="month" choices={months} optionText="description" optionValue="num" label="Mes" alwaysOn />
        <NumberInput source="year" label="Año" alwaysOn />
        <SelectInput source="type_construction" label="Tipo de construcción" choices={type_constructions} optionText="description" optionValue="type_constructions" alwaysOn />
        <SelectInput source="type" label="Tipo de ranking" choices={type} optionText="description" optionValue="type" alwaysOn />
    </Filter>
);

export default class ConstructionCompaniesRankingList extends Component {
    render() {
        return (
            <List {...this.props} title="Listado de Rankig" filters={<ConstructionCompaniesRankingFilter />}>
                <Datagrid>
                    <TextField source="id" label="id" />
                    <ReferenceField label="Constructora" source="construction_company_id" reference="construction-companies">
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="position" label="Posición" />
                    <TextField source="month" label="Mes" />
                    <TextField source="year" label="Año" />
                    <TextField source="type_construction" label="Tipo de construcción" />
                    <TextField source="type" label="Tipo" />
                    <EditButton label="" />
                </Datagrid>
            </List>
        )
    }
};